import * as React from "react"
import "../styles/home.css"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"

const CodingCamp = () => {
  return (
    <Layout title={"Pascendi Gregis | Coding Camp Registration"}>
      <section className="relative bg-gradient-to-r from-[#1D24A1] to-[#5431FF] flex flex-row pt-10">
        <svg
          viewBox="0 0 250 68"
          className="min-w-screen h-full w-full bottom-0"
        >
          <path
            fill="#ceaa72"
            className={"fill-current oneTY"}
            d="M20.4,31.8c3.6,0.3,9.9-4.8,10.1-5c0,0,1.6-0.4,1.1-2.7C31.6,24.2,23.3,23.5,20.4,31.8z M67.6,20.5
		c-0.2,0.1-0.4,0.2-0.7,0.2c-1.9,0.4-5.3,1.5-9,2.6c8.5,14.3,9.8,33.3,9.9,36.3l4.6-0.3C73.8,42,70.4,28.1,67.6,20.5z M39.2,22.7
		c-10.8,0-6.6,31.1-3.9,38.4l4.2-0.2c0.6-11.8,4.1-15,5.1-21.2C46.3,29,43.4,22.7,39.2,22.7z"
          />
          <path
            fill="#ceaa72"
            className={"fill-current twoTY"}
            d="M20.6,31.3c3.6-0.1,9.3-5.9,9.5-6.1c0,0,1.5-0.6,0.8-2.8C30.9,22.4,22.5,22.7,20.6,31.3z M66.7,20.5
		c-0.2,0.1-0.4,0.2-0.7,0.2c-1.9,0.4-5.3,1.5-9,2.6c8.5,14.3,9.8,33.3,9.9,36.3l4.6-0.3C72.9,42.1,69.4,28.2,66.7,20.5z M38.3,22.8
		c-10.8,0-6.6,31.1-3.9,38.4l4.2-0.2c0.6-11.8,4.1-15,5.1-21.2C45.3,29.1,42.4,22.8,38.3,22.8z"
          />
          <path
            fill="#ceaa72"
            className={"fill-current threeTY"}
            d="M57.1,2.6c0,0,0.3,3.7,6.2,3.4c0,0-0.7,1.2-1,2.6c-0.3,1.4-0.9,6.8-2.5,8.8c-1.5,2-4.4,2.5-10,3.6
		c-0.6,0.1-1.2,0.2-1.9,0.4c-7,2-11.1,1.3-16.3,1.1l0,0c-6.3,1-10.4,15.6-10.3,22.1c0.1,6.3,2.6,14.3,3.4,17.1l4.2-0.2
		c0.8-8,4.7-15.3,7.3-20.2c0.2-0.4,0.4-0.8,0.6-1.3c6.8,2.7,15.5,3.9,22.1,1.7c0,0,1.9,3.5,1.8,18.4l4.6-0.2c2.3-9.2,1-22.3,1-22.3
		c0.4-0.3,0.7-0.7,1-1.1c1.7-2.1,2.8-4.3,3.7-6.5c1.7-4.3,2.2-8.6,3.1-12.5c0.4-2,2.4-2.9,6-1.8c1.8,0.4,3.8-1.1,4.6-2.3
		c0.6-0.9,0.5-2.2-0.4-2.9l-7.6-6.7c-2.4-2.5-5.3-2.2-5.3-2.2L57.1,2.6z"
          />
          <path
            fill="#ceaa72"
            className={"fill-current fourTY"}
            d="M55.8,2.3c0,0,0.7,4,6.6,3.7c0,0-0.7,1.2-1,2.6c-0.3,1.4-0.9,6.8-2.5,8.8c-1.5,2-4.4,2.5-10,3.6
		c-0.6,0.1-1.2,0.2-1.9,0.4c-7.9,1.6-13,0.4-16.3,1.1l0,0c-6.3,1-10.4,15.6-10.3,22.1c0.1,6.3,2.6,14.3,3.4,17.1l4.2-0.2
		c0.8-8,4.7-15.3,7.3-20.2c0.2-0.4,0.7-1.2,0.9-1.6c6.8,2.7,15,3.5,21.7,1.4c0,0,2.1,4.2,1.9,19.1l4.6-0.2c2.3-9.2,1-22.3,1-22.3
		c0.4-0.3,0.7-0.7,1-1.1c1.7-2.1,2.8-4.3,3.7-6.5c1.7-4.3,2.2-8.6,3.1-12.5c0.4-2,3.1-3.1,6.7-2c1.8,0.4,3.1-0.9,3.9-2.1
		c0.6-0.9,0.5-2.2-0.4-2.9l-7.6-6.7c-2.4-2.5-5.3-2.2-5.3-2.2L55.8,2.3z"
          />
          <path
            fill="#ceaa72"
            className={"fill-current fiveTY"}
            d="M22.3,56.8c0.2-0.4,0.4-0.7,0.6-1.1C22.8,55.8,22.6,56.1,22.3,56.8z M12.6,60.4c0-0.4-0.1-0.8-0.1-1.1
		C12.4,59.3,12.5,59.7,12.6,60.4z M4.7,60.9c0-0.3,0-0.5-0.1-0.6C4.6,60.3,4.6,60.5,4.7,60.9z M55.1,54.9c0.1-1.1,0.2-2,0.2-2
		C55.2,53.5,55.1,54.2,55.1,54.9z M48.3,54.8c0.2-0.7,0.3-1.2,0.3-1.2C48.5,54,48.4,54.4,48.3,54.8z M38.9,54.3
		c-0.2-0.7-0.4-0.9-0.4-0.9C38.6,53.6,38.7,53.9,38.9,54.3z M56.4,52.8c0.1-0.4,0.2-0.6,0.2-0.6C56.5,52.3,56.5,52.6,56.4,52.8z
		 M231.6,58.3c0-0.4,0.1-0.6,0.1-0.6C231.7,57.9,231.6,58.1,231.6,58.3z M219.4,58.7c0-0.3,0.1-0.5,0.2-0.7
		C219.6,58,219.5,58.3,219.4,58.7z M214,54.7c-0.3-0.7-0.6-1.1-0.6-1.1C213.7,53.9,213.8,54.3,214,54.7z M230.6,60.1c0-1.1,0-2,0-2
		C230.5,58.7,230.5,59.3,230.6,60.1z M241.1,60c0-0.4,0.1-0.6,0.1-0.6C241.1,59.5,241.1,59.7,241.1,60z M228.9,60.1
		c0-0.3,0.1-0.5,0.1-0.7C229,59.4,229,59.7,228.9,60.1z M75.3,53.4c-0.1-0.4-0.2-0.7-0.2-0.7C75.2,52.9,75.3,53.2,75.3,53.4z
		 M62.9,52.2c0-0.3-0.1-0.5-0.1-0.6C62.8,51.5,62.9,51.7,62.9,52.2z M71,52.4c0-0.4-0.1-0.8-0.2-1.1C70.8,51.3,70.9,51.7,71,52.4z
		 M80.6,49.8c0.2-0.4,0.3-0.7,0.5-1C81.2,48.8,80.9,49.1,80.6,49.8z M181,53.5c0-0.7-0.1-1.4-0.2-2C180.8,51.5,180.9,52.4,181,53.5z
		 M179.7,51.4c0-0.3-0.1-0.5-0.2-0.6C179.6,50.8,179.6,51,179.7,51.4z M192.4,55.1c-0.1-0.5-0.2-0.7-0.2-0.7
		C192.2,54.6,192.3,54.8,192.4,55.1z M198.6,55.2c0,0,0,0.1,0.1,0.1C198.6,55.2,198.6,55.2,198.6,55.2z M197.3,52.5
		c0.1-0.3,0.3-0.6,0.4-0.9C197.8,51.5,197.6,51.8,197.3,52.5z M187.8,53.2c-0.1-0.5-0.2-0.9-0.3-1.2
		C187.5,52.1,187.7,52.5,187.8,53.2z M236.3,60c0.1-0.1,0.1-0.1,0.2-0.2C236.5,59.8,236.4,59.9,236.3,60z M36.5,56.2
		c0.1-0.1,0.1-0.2,0.2-0.3C36.7,56,36.6,56.1,36.5,56.2z M54.3,53.9c0.1-0.1,0.1-0.2,0.2-0.3C54.5,53.6,54.4,53.7,54.3,53.9z
		 M121.3,51.2c0.1,0.1,0.1,0.1,0.2,0.2C121.4,51.3,121.3,51.2,121.3,51.2z M42.3,55.4c0.1-0.1,0.1-0.2,0.2-0.3
		C42.5,55.1,42.4,55.2,42.3,55.4z M238.2,60.4c0.1-0.1,0.1-0.1,0.2-0.2C238.4,60.1,238.3,60.2,238.2,60.4z M78,53
		c0,0,0.1,0.1,0.1,0.1C78.1,53.1,78,53,78,53z M75.1,51.9c0.1-0.1,0.1-0.2,0.2-0.3C75.3,51.6,75.2,51.7,75.1,51.9z M89.7,50.8
		c-0.1-0.2-0.2-0.2-0.2-0.2C89.6,50.7,89.6,50.8,89.7,50.8z M62,53.1c0.1-0.1,0.1-0.2,0.2-0.3C62.2,52.8,62.1,52.9,62,53.1z
		 M80.9,51.4c0.1-0.1,0.1-0.2,0.2-0.3C81.1,51.1,81,51.2,80.9,51.4z M167.4,52.8c0-0.1,0.1-0.1,0.2-0.1
		C167.5,52.7,167.5,52.8,167.4,52.8z M177.7,53.6c0,0,0.1-0.1,0.1-0.1C177.9,53.5,177.8,53.5,177.7,53.6z M73.2,52
		c0.1-0.1,0.1-0.2,0.2-0.3C73.4,51.7,73.3,51.8,73.2,52z M17.5,59.3c0.1-0.1,0.1-0.2,0.2-0.3C17.7,59,17.6,59.1,17.5,59.3z
		 M29.3,58.9 M1.9,63.7c0,0,0.1,0.1,0.1,0.1C2,63.7,1.9,63.7,1.9,63.7z M44.9,55.9c-0.1-0.1-0.1-0.1-0.1-0.1
		C44.8,55.8,44.9,55.9,44.9,55.9z M74.5,53.3c0,0,0.1,0.1,0.1,0.1C74.6,53.3,74.5,53.3,74.5,53.3z M74.6,53.4
		C74.7,53.4,74.7,53.4,74.6,53.4C74.7,53.4,74.7,53.4,74.6,53.4z M184.1,54.1C184.1,54.1,184.1,54.1,184.1,54.1
		C184.1,54.1,184.1,54.1,184.1,54.1z M184.1,54.1c0-0.1,0-0.2,0.1-0.3C184.2,53.8,184.2,53.9,184.1,54.1z M39,54.6
		c0-0.1-0.1-0.2-0.1-0.3C38.9,54.4,38.9,54.5,39,54.6z M44.9,55.9C44.9,55.9,45,56,44.9,55.9C45,56,44.9,55.9,44.9,55.9z
		 M231.3,60.6c-0.1-0.1-0.1-0.2-0.1-0.2C231.2,60.5,231.2,60.6,231.3,60.6z M29.2,60.8c0,0.1,0,0.2,0,0.3c-0.1,0.3-0.2,0.5-0.4,0.8
		c0.1-0.8-0.1-1.9-0.9-2.9c0,0-0.1,0.3-0.1,0.6c0,0,0,0,0,0c-0.4-2-1.6-2.6-1.6-2.6c0.7,0.5,1.1,1.3,1.2,2.2 M27.3,59.1
		c0.1,0.5,0.2,1.1,0.2,1.6c0,0.1-0.1,0.2-0.1,0.3c0,0-0.1,0-0.1,0c0-0.3-0.1-0.6-0.2-0.8c0.1-0.3,0.2-0.4,0.2-0.4
		C27.1,59.9,27,60,27,60.1c-0.4-0.9-1.2-1.5-1.2-1.5c0.6,0.3,0.5,1.1,0.3,2c0,0,0,0-0.1,0c0-0.8-0.2-1.4-0.3-1.9
		c0.1-0.3,0.3-0.5,0.6-0.7c0,0-0.3,0.2-0.7,0.6c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.5-1.3-1.1-1.5-1.1-1.5
		c0.5,0.3,0.9,0.9,1.1,1.6c0,0.1-0.1,0.1-0.1,0.2c-0.4,0.4-0.9,1-1.3,1.6c-0.3-0.4-0.6-0.6-0.6-0.6c0.3,0.2,0.4,0.6,0.3,1.1
		c-0.1,0.3-0.2,0.6-0.3,0.9c0,0,0,0-0.1,0c0.1-1.4-0.1-2.8-0.1-2.8c-0.4,0.4-0.7,0.8-1,1.1c0.3-0.7,0.6-1.3,0.9-1.6
		c-0.6,0.7-1,1.3-1.3,1.9c-0.1-0.4-0.2-0.8-0.4-1.1c0.2-1,0.4-1.7,0.6-2.2c-0.3,0.7-0.5,1.4-0.7,2c-0.3-0.5-0.5-0.8-0.5-0.8
		c0.2,0.3,0.4,0.6,0.5,0.9c-0.2,0.7-0.3,1.5-0.4,2.1c-0.2-0.3-0.4-0.6-0.5-0.7c0-0.6,0-1.1-0.2-1.4c0,0-0.1,1.7-0.2,3.1l0,0
		c0,0,0,0,0,0c0,0.2,0,0.3,0,0.4c0-0.8-0.5-1.4-0.8-1.8c0.1-0.9,0.4-1.7,0.4-1.7c-0.6,0.6-0.9,1.2-1.2,1.7c0.1-0.8,0.2-1.3,0.2-1.3
		c-0.3,0.3-0.6,0.7-0.8,1c-0.2-0.2-0.4-0.4-0.4-0.4c0.2,0.1,0.3,0.3,0.4,0.5c-0.3,0.4-0.5,0.9-0.6,1.3c-0.2-0.8-0.1-1.7,0.8-2.4
		c0,0-0.6,0.5-1.3,1.2c0,0,0,0,0,0c0.2-0.5,0.5-1,0.7-1.3c-0.3,0.4-0.6,0.7-0.8,1.1c0,0,0-0.1,0-0.1c0,0,0,0.1,0,0.1
		c-0.3,0.4-0.5,0.8-0.7,1.2c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.2-0.4-0.4-0.4-0.4c0.1,0.1,0.2,0.2,0.3,0.4c-0.1,0-0.2,0-0.3,0
		c0-0.5-0.1-0.9-0.1-1.1c0.2-0.3,0.3-0.5,0.5-0.7c-0.2,0.2-0.3,0.4-0.5,0.6c0,0,0,0,0,0c-0.3,0.3-0.6,0.6-0.8,0.9c0-0.1,0-0.2,0-0.3
		c0.2-0.5,0.5-0.9,0.7-1.1c-0.2,0.3-0.5,0.6-0.7,0.9c0.2-1.1,0.5-1.8,0.5-1.8c-0.4,0.6-0.6,1.4-0.7,2.2c-0.1,0.1-0.2,0.2-0.2,0.3
		c0-0.8,0.2-1.7,0.8-2.3c0,0-0.6,0.6-1.2,1.5c-0.3-0.7-0.6-1.1-0.6-1.1c0.3,0.4,0.5,0.8,0.6,1.2c-0.2,0.4-0.5,0.8-0.7,1.3
		c0-0.6-0.1-1.2-0.1-1.6c0,0.5,0,1.1-0.1,1.6c-0.2-0.2-0.4-0.4-0.4-0.4c0.2,0.1,0.3,0.3,0.3,0.6c-0.1-0.1-0.1-0.1-0.2-0.2
		c-0.3-0.5-0.7-0.8-1-1.1c0.1-0.3,0.2-0.5,0.2-0.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1-0.1-0.2-0.1-0.2-0.1c0.1,0,0.1,0.1,0.2,0.1
		c-0.4,0.4-0.6,0.9-0.8,1.2c0,0,0,0,0,0c0.1-0.7,0.2-1.3,0.2-1.3c-0.6,0.6-1,1.2-1.3,1.8c-0.1-0.2-0.2-0.3-0.3-0.5
		c0,0-0.1,0.5-0.2,1.1c0-0.4,0-0.9,0.2-1.3c0,0-0.3,0.7-0.3,1.8c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3-1.2,0.6-2.7,1.1-3.3
		c-1.4,1.9-2,3.1-2.1,3.9c0-0.4,0.1-0.7,0.1-1c0,0,0,0,0.1-0.1c0,0,0,0-0.1,0.1c0.1-0.5,0.3-0.9,0.3-0.9c-0.2,0.3-0.3,0.6-0.3,0.9
		c0,0,0,0,0,0c0-0.3,0.1-0.5,0.1-0.5c-0.3,0.3-0.5,0.6-0.7,0.9c0,0-0.1,0.1-0.1,0.1c0,0,0-0.1,0-0.1c-0.1-0.1-0.1-0.2-0.2-0.2
		c0-1.1,0.8-2.3,1.1-2.9c-0.8,1-1.3,1.8-1.6,2.5c0,0-0.1,0-0.1-0.1c0-0.1,0-0.2,0-0.3c0-0.2,0-0.5,0-0.7c0,0.1,0,0.3-0.1,0.4
		c0-0.3,0-0.7,0.1-1c0,0.2,0,0.4,0,0.6c0-0.3,0.1-0.5,0.1-0.8c0.1-0.4,0.3-0.8,0.7-1.2c0,0-0.3,0.3-0.6,0.7c0-0.3,0-0.5,0-0.8
		c0,0,0,0.3,0,0.8c-0.3,0.4-0.7,1-1.1,1.7c0.1-1,0-1.7,0-2.2c0,0.7-0.1,1.8-0.3,2.9c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1
		c-0.1-0.1-0.1-0.1-0.2-0.2c-0.3-0.5-0.6-0.8-0.9-1C3.4,60.6,4,59.5,4,59.5c-0.6,0.7-0.9,1.9-1,3.1c-0.2-0.1-0.3-0.2-0.3-0.2
		c0.1,0.1,0.2,0.2,0.3,0.3c0,0.1,0,0.2,0,0.3c-0.1-0.1-0.2-0.1-0.2-0.1c0.1,0,0.2,0.1,0.2,0.1c-0.1,0.5-0.1,0.9-0.1,1.3
		c-0.1,0.1-0.1,0.2-0.2,0.4c-0.2-0.4-0.5-0.6-0.6-0.8C2.4,64.2,2.3,65,2,65.7c-0.1-0.1-0.3-0.1-0.3-0.1c0.1,0,0.2,0.1,0.2,0.1
		c-0.1,0.4-0.3,0.7-0.5,1c0.1-0.6,0-1.3-0.3-2.1c0-0.4,0-0.7,0-0.7c0.9-2.1,0.5-3,0.5-3c0.1,0.9-0.6,2.4-0.6,2.4
		C1,63.7,1,63.9,0.9,64.2c-0.1-0.1-0.2-0.2-0.3-0.4c0,0-0.4,1.7-0.5,3c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0c0,0.1,0,0.2,0,0.4
		c0,0.2,0,0.3,0,0.5c0,0,0,0.1,0,0.1h250c0-0.3-0.1-0.6-0.1-0.9c0,0,0,0,0,0c0,0,0,0,0,0c0.1-1.5-1.7-3.3-1.7-3.3
		c0.6,0.6,0.6,1.4,0.5,2.1c0,0-0.1-0.1-0.1-0.1c-0.4-1.5-2-3.1-2-3.1c0.6,0.6,0.8,1.4,0.6,2.1c-0.1,0-0.2,0-0.4,0.1
		c-0.5-1.1-0.7-2.2,0.2-2.6c0,0-1.2,0.6-1.8,1.6c-0.2-0.2-0.3-0.4-0.4-0.5c0-0.3-0.1-0.6-0.2-0.9c0,0-0.6-1.6-0.6-2.4
		c0,0-0.2,0.4,0,1.4c0,0-0.1,0.1-0.1,0.1c0,0,0.1-0.1,0.1-0.1c0.1,0.4,0.2,1,0.5,1.6c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1
		c0,0,0,0.2,0,0.4c-0.2-0.8-0.4-1.3-0.4-1.3c-0.3,0.2-0.6,0.5-0.9,0.8c0.1-0.6,0.4-1.1,0.6-1.3c-0.4,0.5-0.8,1-1,1.4
		c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1-1.5-0.4-3-1.1-3.9c0,0,0.7,1.2,0.9,3.5c-0.5-1-1-1.6-1-1.6c0.7,0.9,1,1.7,0.9,2.5
		c-0.1-0.1-0.2-0.3-0.4-0.4c0,0,0.2,0.2,0.4,0.5c0,0.3-0.1,0.5-0.1,0.7c-0.5-0.8-1.1-1.4-1.1-1.4c0.6,0.6,0.6,1.4,0.5,2.1
		c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1-0.4-0.2-0.9-0.2-1.4c0.1,0.1,0.2,0.1,0.3,0.2c0,0-0.1-0.2-0.3-0.3c-0.2-1.1-0.3-2.1-0.3-2.9
		c0,0.5-0.1,1.5,0,2.7c-0.2-0.1-0.4-0.2-0.7-0.1c0,0,0,0,0,0c-0.1-0.9,0.1-1.6,0.1-1.6c-0.2,0.2-0.3,0.3-0.4,0.5c0-1,0-1.7,0-1.7
		c-0.1,0.5,0,1.1,0,1.7c-0.6,0.7-0.7,1.3-0.7,2c0,0,0,0,0,0c0,0-0.1,0.2-0.2,0.6c-0.1-0.2-0.2-0.4-0.3-0.6c0.1-0.1,0.2-0.1,0.4-0.1
		c0,0-0.2,0-0.4,0.1c-0.1-0.1-0.1-0.2-0.2-0.4c0,0,0,0,0.1,0c0,0,0,0-0.1,0c-0.4-1-0.5-1.9,0.4-2.3c0,0-0.8,0.4-1.4,1
		c0.1-0.6,0.4-1.1,0.6-1.3c-0.5,0.6-0.8,1.2-1.1,1.6c-0.1-0.6-0.2-0.9-0.2-0.9c0.1,0.3,0.2,0.7,0.2,1c-0.3,0.7-0.4,1.2-0.3,1.7
		c0,0,0,0,0,0.1c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.4-0.1-0.8-0.1-1.1c0.1,0,0.1-0.1,0.2-0.1c0,0-0.1,0-0.2,0.1
		c0-0.3,0-0.4,0-0.4c-0.2,0.2-0.4,0.5-0.5,0.7c-0.1,0-0.2,0.1-0.2,0.1c-0.6-1.3,0.1-2.5,0.5-3c-0.4,0.5-0.8,1-1,1.5
		c0-0.3-0.1-0.5-0.2-0.7c0,0-0.2-0.4-0.3-0.9c0.1-0.3,0.2-0.6,0.4-0.7c0,0-0.2,0.2-0.5,0.5c-0.1-0.4-0.2-0.9-0.2-1.2
		c0,0-0.2,0.4,0,1.4c-0.4,0.4-0.8,1.1-0.9,2c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.6-0.1-1.3-0.2-2c0.1-0.6,0.1-1,0.1-1
		c-0.1,0.3-0.1,0.6-0.1,1c-0.2-1-0.4-1.9-0.9-2.5c0,0,0.6,1,0.9,3c0,0.3,0,0.6-0.1,1c-0.6-0.8-1.3-1.4-1.3-1.4
		c0.3,0.3,0.5,0.5,0.6,0.8c-0.3-0.3-0.5-0.4-0.5-0.4c0.3,0.2,0.4,0.4,0.6,0.6c0.1,0.4,0.2,0.9,0.1,1.4c-0.2-0.3-0.4-0.6-0.6-0.8
		c-0.1-1-0.2-2-0.2-2.6c0,0.3-0.1,0.8,0,1.4c-0.1-0.7-0.3-1.3-0.7-1.8c0,0,0.5,1.1,0.6,2.9c-0.1-0.1-0.1-0.1-0.1-0.2
		c0.1,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1c-0.1,0-0.3-0.1-0.5,0c-0.1-0.1-0.2-0.2-0.2-0.3c0.1,0,0.2,0,0.3,0c0,0-0.1,0-0.3-0.1
		c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.3-0.1-0.4c0,0.1,0,0.2,0,0.3c-0.4-0.5-0.7-0.8-0.7-0.8c0.3,0.3,0.5,0.6,0.6,1c-0.1,0-0.2,0-0.3,0
		c-0.1-0.1-0.2-0.3-0.3-0.4c0,0,0,0.2,0,0.5c0,0,0,0-0.1,0c-0.1-0.7,0-1.3,0.6-1.6c0,0-0.8,0.4-1.4,1.1c0,0,0-0.1,0-0.1
		c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c-0.1,0.1-0.2,0.2-0.3,0.4c-0.4-0.5-0.7-0.9-0.7-0.9
		c0.3,0.3,0.5,0.7,0.5,1.2c-0.1,0-0.2-0.1-0.3-0.1c-0.2-1.1-0.5-2-0.5-2c-0.4,0.3-0.6,0.5-0.9,0.8c0.1-0.6,0.4-1.1,0.6-1.4
		c-0.4,0.5-0.8,1-1,1.5c-0.4-1.4-1.3-2.5-1.3-2.5c1,1.2,1.1,2.3,0.8,3.2c-0.1-0.5-0.1-0.9-0.1-1.3c0,0,0,0.6,0,1.3
		c-0.5-0.7-1-1.3-1-1.3c0.5,0.4,0.6,1,0.5,1.6c-0.1-0.3-0.3-0.5-0.5-0.7c0,0,0.3,0.5,0.4,1.1c0,0,0,0,0,0c-0.2-0.1-0.3-0.2-0.3-0.2
		c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1-0.8-0.6-1.7-1.1-2.3c-0.1-0.6-0.1-1.1-0.1-1.7c0-0.1,0-0.2,0-0.2
		c0,0,0,0,0,0c0.1-0.7,0.2-1.1,0.2-1.1c-0.1,0.3-0.1,0.7-0.2,1.1c-0.5,0.4-0.7,0.9-0.9,1.3c-0.1-0.8,0-1.3,0-1.3
		c-0.2,0.2-0.4,0.4-0.6,0.7c-0.1-0.8-0.3-1.5-0.7-2.1c0,0,0.4,0.9,0.5,2.4c-0.2,0.4-0.3,0.8-0.4,1.2c-0.2,0.1-0.4,0.2-0.6,0.3
		c-0.2-0.5-0.2-0.9-0.2-1.4c0.2,0,0.5,0,0.8,0c0,0-0.3-0.1-0.8-0.1c0.1-0.7,0.5-1.3,0.7-1.6c-0.5,0.6-0.9,1.2-1.1,1.7
		c-0.2,0.1-0.4,0.1-0.5,0.3c0-0.1,0-0.1,0-0.1c-0.2,0.1-0.3,0.3-0.4,0.5c0-0.4-0.1-0.8,0-1.1c0,0.3-0.1,0.7-0.1,1.1
		c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2-1.1,0.4-2.1,0.7-2.5c-0.7,0.8-1.1,1.5-1.3,2.1c0,0,0-0.1,0-0.1c0.1-0.2,0.1-0.3,0.1-0.3
		c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3-1.1-0.4-2.2,0.3-2.8c0,0-1.3,1-1.6,2.6c-0.1-0.1-0.2-0.2-0.3-0.4c-0.2-0.3-0.4-0.6-0.7-0.9
		c0-0.5,0-0.9,0-0.9c-0.1,0.2-0.1,0.4-0.1,0.7c-0.4-0.4-0.7-0.7-0.7-0.7c0.3,0.3,0.5,0.5,0.6,0.8c-0.3-0.3-0.5-0.4-0.5-0.4
		c0.3,0.2,0.4,0.3,0.6,0.5c0,0.1,0,0.2,0.1,0.3c0,0.3,0,0.7,0,1.1c0,0.1-0.1,0.3-0.1,0.4c0,0,0,0,0,0c-0.2-0.3-0.3-0.6-0.5-0.8
		c-0.2-1.2-0.5-2.6-1.1-4c0.3,0.7,0.6,1.9,0.8,3.6c-0.3-0.3-0.5-0.6-0.5-0.6c0.3,0.2,0.4,0.5,0.5,0.8c-0.4-0.5-0.7-0.9-0.7-0.8
		c0.4,0.4,0.5,0.9,0.5,1.4c-0.1,0-0.2,0-0.4,0.1c-0.5-1.3-1.7-2.4-1.7-2.4c0.2,0.2,0.4,0.4,0.5,0.7c-0.1,0.3-0.2,0.8-0.2,1.5
		c0,0-0.1,0-0.1,0c-0.5-1.1-0.7-2.2,0.3-2.6c0,0-1,0.6-1.7,1.5c-0.2-0.4-0.3-0.9-0.6-1.4c0.2-0.7,0.5-1.2,1.1-1.6
		c0,0-0.7,0.3-1.1,1.5c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0.2-0.2,0.5-0.2,0.8c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1
		c-0.1-0.6-0.3-1.1-0.3-1.1c-0.4,0.3-0.7,0.5-0.9,0.8c0.2-0.6,0.5-1.1,0.7-1.4c-0.4,0.5-0.8,1-1,1.5c0.2-0.9,0.6-1.8,1.3-2.4
		c0,0-0.9,0.5-1.5,2c-0.5-1.2-1.2-2-1.2-2c1,1.2,1,2.4,0.7,3.3c-0.5-0.8-1-1.4-1-1.4c0.6,0.5,0.6,1.3,0.4,2.1
		c-0.2-0.1-0.3-0.2-0.3-0.2c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0.2-0.1,0.4-0.2,0.6c0-0.5-0.2-1-0.5-1.5c-0.1-0.8-0.3-1.3-0.5-1.8
		c0.1-0.4,0.2-0.8,0.3-1c0,0-0.2,0.2-0.4,1c0-0.1-0.1-0.2-0.1-0.3c0-0.3,0.1-0.5,0.1-0.5c-0.1,0.1-0.2,0.2-0.2,0.2
		c-0.1-0.2-0.2-0.3-0.3-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0,0-0.1,0-0.3,0.2c-0.2-0.2-0.3-0.3-0.3-0.3c0.1,0.1,0.2,0.2,0.3,0.3
		c-0.1,0.1-0.3,0.3-0.4,0.7c0-0.3,0-0.5,0-0.5c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2-0.5-0.4-0.7-0.4-0.7c0.1,0.2,0.2,0.4,0.3,0.7
		c-0.6,0.6-0.9,1.3-1,2c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1-0.4-0.2-0.7-0.3-1.1c0.1-0.8,0.5-1.6,0.7-1.9c-0.4,0.5-0.7,0.9-0.9,1.4
		c-0.3-0.7-0.6-1.2-0.9-1.6c0-0.1,0.1-0.1,0.1-0.2c0,0-0.1,0.1-0.1,0.2c-0.5-0.7-1-1-1-1c0.4,0.3,0.7,0.7,0.9,1.1
		c-0.3,0.4-0.6,0.8-0.8,1.2c-0.1-0.3-0.2-0.6-0.3-0.8c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.2-0.2-0.4-0.3-0.6c0.1-0.3,0.2-0.5,0.4-0.7
		c0,0-0.2,0.2-0.5,0.5c-0.5-0.8-1-1.1-0.9-1.1c0.4,0.3,0.7,0.7,0.9,1.1c-0.4,0.5-0.9,1.2-1.1,2.1c-0.1-0.1-0.2-0.2-0.3-0.4
		c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.1-0.1-0.2-0.1-0.3c0,0.1,0,0.1,0.1,0.2c-0.6-0.7-1.2-1.2-1.2-1.2c0.3,0.2,0.5,0.5,0.6,0.8
		c-0.3-0.3-0.5-0.4-0.5-0.4c0.3,0.2,0.4,0.3,0.6,0.5c0.2,0.5,0.1,1.1,0,1.6c0,0-0.1,0-0.1,0.1c-0.4-0.7-1-1.4-1.1-1.5
		c0-1.4,0.2-2.4,0.4-3c-0.5,1.3-0.6,2.8-0.6,4.1c-0.2-0.4-0.5-0.7-0.7-0.9c-0.1-0.6-0.2-1.2-0.4-1.5c0,0,0.1,0.5,0.1,1.3
		c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2c-0.1-0.1-0.2-0.2-0.3-0.4c0,0,0,0.4-0.1,0.9c-0.6-0.9-1.3-1.6-1.3-1.6
		c0.6,0.5,0.7,1.3,0.6,2.1c-0.1,0-0.2,0.1-0.4,0.1c-0.5-1.1-0.6-2.2,0.3-2.6c0,0-1.2,0.7-1.9,1.8c-0.2-0.2-0.3-0.4-0.4-0.5
		c0,0,0-0.1,0-0.1c0,0,0,0.1,0,0.1c-0.2-0.2-0.3-0.3-0.3-0.3c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.3,0.1,0.7,0.1,1
		c-0.1-0.1-0.2-0.2-0.2-0.3c-0.2-1.1-0.4-2-0.4-2c-0.4,0.3-0.7,0.6-0.9,0.9c0.2-0.6,0.5-1.1,0.7-1.4c-0.5,0.6-0.8,1.1-1.1,1.5
		c-0.2-0.7-0.5-1.3-0.8-1.7c0.1-0.6,0.2-1,0.2-1c-0.2,0.2-0.3,0.5-0.3,0.8c-0.2-0.3-0.4-0.5-0.4-0.5c0.1,0.2,0.3,0.3,0.4,0.5
		c-0.2,0.7-0.1,1.5,0,2.3c-0.4-0.5-0.7-0.8-0.7-0.8c0.6,0.5,0.6,1.3,0.4,2.1c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.7-0.7-1.2-0.9-1.4
		c-0.1-0.5-0.2-1-0.3-1.4c0-0.3,0.1-0.5,0.1-0.5c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0-0.1,0-0.2c0,0.1,0,0.1,0,0.2
		c-0.4,0.4-0.6,0.8-0.8,1.2c0-0.8,0-1.3,0-1.3c-0.4,0.4-0.7,0.8-0.9,1.3c0-0.3-0.1-0.4-0.1-0.4c0,0.1,0,0.3,0.1,0.5
		c-0.1,0.3-0.3,0.7-0.3,1c-0.2,0.1-0.4,0.3-0.6,0.4c-0.5-1.3,0.2-2.6,0.6-3.1c-0.9,1.1-1.3,1.9-1.5,2.6c0-0.1-0.1-0.2-0.1-0.3
		c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.4-0.1-0.7-0.1-1.1c0,0.2-0.1,0.6,0,1.1c0,0,0,0-0.1,0c0-0.3,0-0.4,0-0.5c-0.3,0.2-0.4,0.5-0.6,0.8
		c-0.1,0.1-0.2,0.1-0.2,0.2c-0.5-1.3,0.2-2.6,0.6-3.1c-0.7,0.8-1.1,1.6-1.4,2.2c-0.3-1.2-0.4-2.3,0.3-3c0,0-1.3,1.1-1.7,2.7
		c0,0,0,0,0,0c0-0.3,0-0.7-0.1-1c0,0.3,0,0.6,0,0.9c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.2-0.4-0.5-0.7-0.8-1
		c-0.1-0.8-0.2-1.4-0.3-1.8c0.1,0.4,0.1,1,0.1,1.6c-0.3-0.3-0.6-0.5-0.6-0.5c0.3,0.2,0.4,0.5,0.6,0.8c-0.3-0.2-0.5-0.4-0.5-0.4
		c0.2,0.1,0.4,0.3,0.5,0.5c0,0.3,0,0.6,0,0.9c-0.2,0.1-0.3,0.2-0.3,0.2c0.1-0.1,0.2-0.1,0.3-0.2c0,0.3,0,0.5,0,0.8c0,0,0,0,0,0.1
		c0,0,0,0,0,0c-0.1-0.3-0.3-0.5-0.5-0.7c-0.1-0.5-0.2-0.9-0.2-0.9c-0.1,0.1-0.2,0.2-0.3,0.4c0,0,0-0.1-0.1-0.1
		c0.1-0.1,0.1-0.1,0.1-0.1c0,0-0.1,0-0.1,0.1c-0.1-0.1-0.2-0.2-0.2-0.2c0.1,0.1,0.1,0.2,0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3
		c-0.4-3,0.4-4.3,0.4-4.3c-0.7,0.8-0.7,2.8-0.5,4.5c-0.3,0.6-0.3,1.4-0.3,2c-0.1-0.3-0.2-0.5-0.3-0.8c0.1-0.1,0.1-0.1,0.3-0.1
		c0,0-0.1,0-0.3,0.1c-0.2-0.7-0.3-1.4,0-1.8c-0.2,0.2-0.5,0.6-0.8,1c0-0.6,0-1.2-0.2-1.7c0.1-0.1,0.2-0.2,0.4-0.2
		c0,0-0.2,0.1-0.4,0.2c0,0,0,0,0,0c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.2-0.3,0.3-0.4c0,0-0.1,0.1-0.3,0.2c0.2-1.3-0.1-2-0.1-2
		c0.1,0.6,0,1.4-0.1,1.9c-0.2-0.5-0.4-1.3-0.3-1.9c0,0-0.3,0.7,0.2,2.3c-0.2,0.2-0.5,0.5-0.7,0.9c-0.1,0.1-0.2,0.2-0.3,0.3
		c-0.1-0.5-0.3-0.9-0.6-1.3c-0.1-1.3-0.3-2.7-0.9-3.4c0,0,0.6,1,0.7,3.2c-0.6-0.9-1.3-1.6-1.3-1.6c0.8,0.8,0.8,2,0.6,3.1
		c-0.2-0.4-0.4-0.8-0.7-1.2c-0.1-0.7-0.1-1.4,0-1.9c-0.1,0.4-0.1,0.9-0.1,1.7c-0.2-0.3-0.4-0.6-0.7-0.9c0.2,0.3,0.5,0.7,0.7,1.2
		c0,0.2,0,0.5,0,0.8c-0.2-0.1-0.6-0.1-0.9,0c0-0.3-0.1-0.6-0.1-0.9c0,0.3,0,0.6,0,1c0,0-0.1,0-0.1,0.1c-0.3-0.6-0.8-1.4-1.5-2.2
		c0.4,0.5,1.1,1.6,1,2.6c-0.1,0.1-0.2,0.2-0.3,0.4c0,0,0,0,0,0c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.8-0.7-1.7-1.5-2.5
		c0,0,0.1,0.6,0.1,1.3c-0.2-0.5-0.6-1-1.1-1.5c0,0,0.3,0.7,0.3,1.7c-0.4,0.5-1,1.3-1,2.2c-0.1-0.2-0.2-0.4-0.3-0.7
		c0.1-0.1,0.1-0.1,0.3-0.1c0,0-0.1,0-0.3,0.1c-0.2-0.7-0.3-1.4,0-1.8c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.3,0-0.5,0-0.8c0,0,0,0.3-0.1,0.8
		c-0.1,0.2-0.3,0.4-0.4,0.6c-0.4-1-0.5-2.2,0.4-3.2c0,0-0.8,0.8-1.1,2.2c-0.2-0.4-0.4-0.7-0.8-1.2c0-0.1,0-0.2,0-0.4
		c0,0.1,0,0.2-0.1,0.3c-0.1-0.2-0.3-0.4-0.4-0.5c0.1,0.2,0.3,0.4,0.4,0.6c0,0.1,0,0.2-0.1,0.4c-0.2-0.2-0.3-0.3-0.5-0.5
		c0,0-0.3,1.3-0.3,2.7c0,0,0,0.1-0.1,0.1c0-0.3-0.1-0.5-0.3-0.8c0-0.5,0.1-0.9,0.4-1.1c0,0-0.3,0.3-0.6,0.7c0,0-0.1-0.1-0.1-0.1
		c0-1.1-0.1-2.3-0.6-3.1c0,0,0.4,1,0.4,2.7c-0.8-1-1.7-1.7-1.7-1.7c0.6,0.4,0.8,1,0.8,1.6c-0.2,0-0.5,0.1-0.8,0.2
		c0-0.5,0.1-1,0.5-1.2c0,0-0.9,0.7-1.4,1.7c0,0,0-0.1,0-0.1c0-0.3,0-0.6,0-0.8c0,0.2-0.1,0.5-0.1,0.7c-0.1-0.2-0.3-0.4-0.4-0.5
		c0.1-0.2,0.2-0.3,0.3-0.4c0,0-0.2,0.1-0.4,0.4c-0.2-0.3-0.4-0.4-0.4-0.4c0.2,0.1,0.3,0.3,0.3,0.5c-0.1,0.2-0.3,0.4-0.5,0.6
		c0-0.1-0.1-0.2-0.1-0.3c0.1-0.2,0.2-0.4,0.2-0.4c-0.1,0.1-0.2,0.2-0.2,0.3c-0.5-0.9-1.3-1.5-1.3-1.5c0.6,0.5,0.7,1.3,0.5,2
		c-0.1,0-0.2,0.1-0.4,0.1c-0.4-1-0.6-2.1,0.4-2.7c0,0-0.8,0.5-1.5,1.3c0-0.8,0-1.3,0-1.3c-0.2,0.4-0.2,1-0.3,1.6
		c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2-0.2-0.3-0.4-0.5-0.6c-0.1-1.1-0.4-2.2-0.8-3.3c0.2,0.7,0.5,1.6,0.6,3.1c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0.2,0,0.3,0,0.5c-0.1-0.8-0.3-1.4-0.3-1.4c-0.4,0.3-0.7,0.6-0.9,0.9c0.2-0.6,0.5-1.1,0.7-1.4c-0.5,0.6-0.8,1.1-1.1,1.6
		c0-0.2-0.1-0.4-0.2-0.5c0,0,0-0.1,0-0.1c0,0,0,0.1,0,0.1c-0.4-1.1-1-1.8-1-1.8c1,1.1,0.9,2.3,0.6,3.3c-0.4-0.7-1-1.3-1-1.3
		c0.6,0.5,0.5,1.3,0.3,2.1c-0.2-0.1-0.3-0.2-0.3-0.1c0.1,0,0.2,0.1,0.3,0.2c-0.1,0.2-0.1,0.4-0.2,0.6c0-0.2,0-0.4-0.1-0.6
		c0-0.8-0.3-1.8-0.9-2.9c0.2-0.7,0.6-1.3,1.2-1.7c0,0-0.7,0.3-1.2,1.6c0.1-0.3,0.1-0.4,0.1-0.4c-0.5,0.5-0.8,1-1.1,1.4
		c0-0.8,0.1-1.3,0.1-1.3c-0.4,0.3-0.7,0.7-0.9,1.1c0.2-0.9,0.7-1.8,1.4-2.4c0,0-1.6,1-2,3.7c-0.2,0.1-0.4,0.2-0.5,0.3
		c-0.5-1.3,0.3-2.6,0.7-3.1c-1,1.3-1.5,2.2-1.7,3c0-0.2,0-0.4-0.1-0.5c0,0,0,0,0.1,0c0,0,0,0-0.1,0c-0.1-0.7-0.2-1.2-0.4-1.6
		c0.1-0.4,0.2-0.8,0.4-1.1c0,0-0.2,0.2-0.4,1c0-0.1-0.1-0.2-0.2-0.3c0.1-0.2,0.3-0.4,0.4-0.6c-0.1,0.2-0.3,0.3-0.4,0.5c0,0,0,0,0,0
		c0.1-0.3,0.3-0.5,0.6-0.7c0,0-0.3,0.1-0.6,0.6c-0.3-0.5-0.6-0.7-0.6-0.7c0.2,0.2,0.4,0.5,0.6,0.7c-0.1,0.1-0.1,0.3-0.2,0.4
		c-0.2-0.8-0.4-1.1-0.4-1.1c0.2,0.3,0.3,0.7,0.4,1.1c0,0,0,0.1,0,0.1c-0.3,0.5-0.5,0.9-0.7,1.2c-0.3-1.1-0.4-2.3,0.4-3
		c0,0-1.2,1-1.7,2.5c-0.6-2-1.9-2.9-1.9-2.9c0.9,0.8,1.4,1.9,1.6,3c0,0-0.1-0.1-0.1-0.1c-0.6-1-1.5-1.6-1.5-1.6
		c0.3,0.2,0.4,0.5,0.5,0.8c-0.3-0.2-0.5-0.4-0.5-0.4c0.2,0.1,0.4,0.3,0.5,0.5c0.2,0.5,0.1,1.2-0.1,1.8c0,0,0,0,0,0
		c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.8-0.3-1.5-0.6-2c0,0,0,0,0,0c0,0,0,0,0,0c-0.6-1.4-1.3-1.7-1.3-1.7c0.6,0.5,1,1.1,1.3,1.8
		c-0.4,0.8-0.6,1.6-0.7,2.2c-0.1-0.3-0.2-0.6-0.4-0.9c-0.1-0.4-0.1-0.7-0.1-0.7c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1-0.1-0.1-0.2-0.2-0.3
		c0,0,0,0.3-0.1,0.7c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1-0.6-0.2-1-0.3-1.3c0.2,0.4,0.2,1.1,0.2,1.7c-0.1,0.3-0.2,0.5-0.2,0.8
		c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.2-0.2-0.4-0.3-0.6c0.1-0.1,0.2-0.2,0.2-0.2c0,0,1-0.7-0.2,0.1c0,0,0,0-0.1,0.1
		c-0.1-0.2-0.3-0.5-0.5-0.7c0,0,0,0.3,0.1,0.7c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.2-0.1c-0.2-0.6-0.2-1.1-0.1-1.6
		c0.1-0.2,0.3-0.3,0.5-0.4c0,0-0.2,0.1-0.5,0.3c0.1-0.3,0.3-0.5,0.5-0.7c0,0-0.3,0.2-0.7,0.6c0.1-1.2,0.3-2,0.5-2.6
		c-0.4,0.9-0.6,1.9-0.7,2.8c-0.2,0.2-0.4,0.4-0.5,0.7c-0.1,0.1-0.1,0.1-0.2,0.2c0-0.7-0.1-1.4-0.3-1.8c0,0,0,0.7,0.1,1.6
		c-0.6-1.5-1.8-2.6-1.8-2.6c0.8,0.8,0.7,1.9,0.5,3.1c-0.3-0.6-0.8-1.4-1.5-2.3c0.4,0.5,1.1,1.6,0.9,2.8c-0.2-0.3-0.4-0.6-0.6-0.9
		c0,0,0.1,0.3,0.3,0.7c0,0-0.1,0-0.1,0c0,0,0.1,0,0.1,0c0.1,0.1,0.1,0.3,0.2,0.5c0,0,0,0.1,0,0.1c-0.1-0.1-0.2-0.1-0.2-0.2
		c-0.1-0.1-0.1-0.3-0.2-0.4c0.1,0,0.1,0,0.2,0c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.2-0.3-0.4-0.4c0,0,0,0.2,0,0.5
		c-0.1-0.1-0.2-0.1-0.2-0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0c-0.1-0.3-0.2-0.6-0.4-0.9c0,0,0-0.1,0-0.2
		c0,0,0,0.1,0,0.1c-0.3-0.5-0.7-1.1-1.2-1.7c0.3,0.4,0.9,1.4,0.9,2.4c-0.4-0.3-0.8-0.5-1.1-0.6c-0.2-0.5-0.6-1-1.1-1.5
		c0,0,0.1,0.6,0.1,1.3c-0.1-0.1-0.2-0.3-0.3-0.4c0-1.3,0.3-2.1,0.3-2.1c-0.3,0.5-0.5,1.1-0.5,1.8c-0.2-0.2-0.4-0.5-0.6-0.7
		c0,0,0.3,0.7,0.3,1.7c-0.2,0.2-0.4,0.5-0.6,0.8c-0.2-0.2-0.3-0.4-0.4-0.6c0-0.2,0-0.4-0.1-0.6c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.1-0.5-0.1-0.9-0.2-1.3c0.1,0.4,0.1,0.9,0.1,1.4c-0.2,0.2-0.5,0.6-0.8,1c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1,0-0.1c0,0,0,0-0.1,0.1
		c-0.3-1-0.3-2,0.6-3c0,0-0.8,0.9-1.1,2.3c-0.3-0.5-0.7-1-1.2-1.7c0.2,0.3,0.5,0.7,0.7,1.2c0,0,0,0.1,0,0.1
		c-0.2-0.3-0.5-0.6-0.9-0.9c0,0-0.4,1.4-0.4,2.9c0,0,0,0-0.1,0c0-0.3-0.1-0.6-0.2-0.8c0-0.5,0.1-0.9,0.4-1.2c0,0-0.3,0.3-0.7,0.8
		c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.3,0-0.5,0.1-0.7c0,0-0.1,0.3-0.1,0.7c-0.7-1-1.8-1.6-1.8-1.6c1,0.6,0.9,1.7,0.5,2.7c0,0,0,0,0,0
		c0-0.7,0.2-1.2,0.2-1.2c-0.2,0.4-0.3,0.8-0.3,1.1c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2-0.8-0.2-1.6,0.5-2c0,0-0.8,0.7-1.4,1.7
		c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.2,0-0.3,0-0.5c0.1-0.1,0.1-0.1,0.2-0.2c0,0-0.1,0.1-0.2,0.2c0-0.2,0-0.3,0-0.5c0,0.2,0,0.4,0,0.5
		c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3-0.1-0.5-0.1-0.7,0c0-1.2-0.1-2.2-0.2-2.7c0.1,0.7,0.1,1.6,0,2.6c-0.1-0.1-0.1-0.1-0.1-0.1
		c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2c-0.2,0.1-0.3,0.3-0.3,0.3c0.1-0.1,0.2-0.1,0.3-0.2c0,0.5-0.1,0.9-0.1,1.3
		c-0.1-0.1-0.2-0.1-0.2-0.1c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0,0.2,0,0.4c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1-0.3-0.3-0.6-0.4-0.9
		c0.1-0.1,0.1-0.1,0.3-0.2c0,0-0.1,0.1-0.3,0.1c-0.2-0.7-0.3-1.4,0.1-1.9c-0.2,0.2-0.4,0.4-0.6,0.7c0-0.1,0-0.1,0-0.2
		c0.2-0.4,0.4-0.6,0.4-0.6c-0.2,0.1-0.3,0.2-0.5,0.4c-0.1-0.7-0.1-1.3,0-1.8c0,0,0,0,0,0c0,0,0,0,0,0c0.1-1.7,0.6-2.6,0.6-2.6
		c-0.5,0.6-0.7,1.6-0.7,2.7c-0.3,0.2-0.9,0.7-1.5,1.3c0-0.2-0.1-0.4-0.1-0.6c0.4-1.6,0.1-2.3,0.1-2.3c0.1,0.6-0.1,1.4-0.2,1.9
		c-0.1-0.5-0.3-1.3-0.2-1.9c0,0-0.4,0.8,0.1,2.3c-0.1,0.3-0.2,0.6-0.2,1c-0.3-0.3-0.5-0.5-0.5-0.5c0.4,0.3,0.5,0.7,0.4,1.2
		c-0.1,0.3-0.2,0.5-0.2,0.8c0,0,0,0-0.1,0c0-1.4-0.4-2.9-0.4-2.9c-0.3,0.2-0.5,0.4-0.7,0.7c0-0.1,0-0.2,0-0.4
		c0.2-0.3,0.4-0.6,0.5-0.8c-0.2,0.2-0.4,0.4-0.5,0.6c0-1.2-0.2-2.4-0.7-3c0,0,0.6,1,0.6,3.2c-0.2,0.3-0.3,0.5-0.5,0.7c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c-0.2-0.9-0.6-1.6-0.9-1.9c0-0.2,0-0.4,0-0.5c0,0.1-0.1,0.3-0.1,0.5c-0.1-0.2-0.2-0.3-0.2-0.3
		c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0.6-0.2,1.3-0.2,2.2c0,0,0,0-0.1,0c-0.2-0.3-0.4-0.5-0.4-0.5c0.2,0.1,0.3,0.3,0.3,0.5
		c-0.2,0-0.5,0-0.7,0.2c0-0.3-0.1-0.6-0.1-0.9c0,0.3,0,0.6,0,1c-0.1,0-0.1,0.1-0.2,0.1c-0.1-0.2-0.2-0.3-0.3-0.4
		c0-0.9,0.3-1.7,0.3-1.7c-0.5,0.5-0.9,1-1.1,1.5c0-0.8,0.1-1.3,0.1-1.3c-0.8,0.7-1.2,1.6-1.4,2.4c-0.1,0.1-0.3,0.2-0.4,0.2
		c0.2-1.1-0.1-1.8-0.1-1.8c0.2,0.5,0.1,1.3-0.1,2c0,0-0.1,0-0.1,0.1c-0.4-1.2,0.4-2.6,0.8-3.2c-1.7,2.1-2,3.4-1.8,4.2c0,0,0,0,0,0
		c0-0.2-0.1-0.3-0.1-0.5c0,0,0,0,0,0c0,0,0,0,0,0c0-0.4,0-0.8,0-1.1c0.1,0,0.1-0.1,0.2-0.1c0,0-0.1,0-0.2,0.1c0-0.3,0-0.5,0-0.5
		c-0.3,0.3-0.5,0.5-0.6,0.8c0,0,0,0,0,0c-0.1-0.6-0.1-1.3,0-1.8c0,0-0.1,0.9-0.2,1.9c0,0,0,0,0,0c-0.4-1.2,0.4-2.6,0.8-3.2
		c-0.7,0.9-1.2,1.7-1.5,2.3c-0.3-1.1-0.3-2.3,0.5-3.1c0,0-0.9,0.8-1.5,1.9c0-0.3,0-0.6,0.1-0.9c-0.1,0.3-0.1,0.8-0.2,1.2
		c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.4-0.6-0.7-0.8-1c0-0.7-0.2-1.3-0.5-1.8c0,0,0.2,0.6,0.3,1.6
		c-0.2-0.2-0.4-0.3-0.4-0.3c0.2,0.1,0.3,0.3,0.4,0.4c0,0.1,0,0.1,0,0.2c-0.2-0.1-0.4-0.2-0.4-0.2c0.1,0.1,0.3,0.1,0.4,0.2
		c0,0.5,0,1.1-0.1,1.7c-0.2-0.4-0.5-0.7-0.7-0.9c0.1,0,0.3,0,0.4,0c0,0-0.2,0-0.4,0c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1,0-0.2,0-0.2
		c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.1-0.3,0.1-0.5,0.3c-0.1-0.2-0.3-0.4-0.4-0.6
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c0,0,0,0.1,0,0.2c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0-0.1c0,0,0,0.1,0,0.1
		c-0.2,0.2-0.5,0.6-0.7,1c-0.1,0-0.2,0-0.3,0c0-0.2-0.1-0.4-0.1-0.5c0.1-0.1,0.1-0.2,0.1-0.2c0,0-0.1,0.1-0.1,0.1
		c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.3-0.3,0.5-0.5c0,0-0.2,0.1-0.5,0.4c0.1-0.3,0.3-0.5,0.5-0.8c0,0-0.9,0.6-1.5,1.6
		c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4-1.7-1.8-2.8-1.8-2.8c0.3,0.3,0.5,0.7,0.6,1.1c-0.2,0.4-0.2,1-0.3,1.7c-0.2-0.4-0.4-0.8-0.7-1.2
		c-0.1-0.8-0.3-1.7-0.6-2.4c0.2,0.5,0.3,1.2,0.4,2.2c-0.1-0.2-0.3-0.4-0.4-0.5c0.1,0.2,0.3,0.4,0.4,0.7c0,0.6,0.1,1.2,0,2
		c0,0-0.1,0-0.1,0c-0.1-0.2-0.3-0.4-0.5-0.6c0,0,0,0.2,0,0.5c-0.1-0.1-0.2-0.1-0.2-0.1c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0,0.2,0,0.2
		c-0.1,0-0.1,0.1-0.2,0.1c-0.1-0.5-0.4-1.1-0.9-1.9c0,0,0,0,0-0.1c0,0,0,0,0,0.1c-0.2-0.3-0.4-0.6-0.7-1c0.2,0.2,0.4,0.6,0.6,1.1
		c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0.4,0.3,0.8,0.2,1.3c-0.5-0.3-0.2-0.1,0,0c0,0.2-0.1,0.4-0.1,0.6c-0.1-0.1-0.2-0.1-0.3-0.2
		c0-0.6,0-1.3,0.2-1.8c-0.1,0.3-0.3,0.8-0.4,1.6c0,0-0.1-0.1-0.2-0.1c-0.2-0.8-0.6-1.7-1.3-2.3c0,0,0.1,0.6,0,1.3
		c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.2-0.1-0.4-0.2-0.6c0.3-0.7,0.7-1.4,1.3-1.8c0,0-0.7,0.4-1.3,1.8c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0.1-0.1,0.1c-0.1-0.2-0.3-0.4-0.5-0.6c0,0,0.2,0.6,0.2,1.4c0,0.1-0.1,0.2-0.1,0.3c-0.3,0.3-0.6,0.8-0.8,1.3
		c-0.1-0.4-0.2-0.7-0.5-1c0.1-0.3,0.2-0.6,0.4-0.7c0,0-0.3,0.3-0.6,0.7c0.2-1.1,0.7-2.3,1.7-3.2c0,0-1.5,1-2.1,3.6
		c-0.3-0.9-0.3-2,0.6-3.1c0,0-0.8,0.9-1.2,2.3c-0.2-0.4-0.6-0.9-1-1.5c0.1-0.1,0.1-0.2,0.2-0.3c0,0-0.1,0.1-0.2,0.3
		c0,0-0.1-0.1-0.1-0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.1-0.1,0.3-0.2,0.5c0,0-0.1-0.1-0.1-0.1c0,0,0,0.1-0.1,0.2
		c-0.1-0.3-0.3-0.5-0.4-0.6c0.1-0.1,0.2-0.2,0.3-0.3c0,0-0.1,0.1-0.3,0.2c-0.2-0.2-0.3-0.2-0.3-0.2c0.1,0.1,0.2,0.2,0.2,0.3
		c-0.2,0.2-0.3,0.5-0.5,0.9c-0.2-0.8-0.4-1-0.4-1c0.2,0.3,0.3,0.6,0.4,1.1c-0.1,0.2-0.2,0.4-0.2,0.7c-0.7-0.8-1.6-1.4-1.6-1.4
		c1,0.5,0.8,1.6,0.4,2.7c-0.2-0.1-0.3-0.1-0.4-0.1c-0.2-0.8-0.1-1.6,0.5-2c0,0-0.4,0.4-0.9,1c-0.6-1.8-1.7-2.5-1.7-2.5
		c0.9,0.7,1.3,1.7,1.5,2.8c-0.1,0.1-0.1,0.2-0.2,0.3c0,0-0.1,0.1-0.1,0.2c-0.2-0.2-0.3-0.4-0.5-0.6c0.1-0.1,0.1-0.2,0.2-0.3
		c0,0-0.1,0.1-0.3,0.3c-0.1-0.1-0.2-0.2-0.2-0.2c0.1,0.1,0.2,0.2,0.2,0.2c-0.1,0.2-0.3,0.4-0.5,0.7c-0.1-0.1-0.1-0.2-0.2-0.3
		c-0.1-0.5-0.2-1-0.4-1.4c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.1-0.2-0.2-0.4c0,0,0,0,0,0c0,0,0,0,0,0c-0.5-1-1-1.3-1-1.3
		c0.5,0.3,0.8,0.8,1,1.3c-0.2,0.2-1.3,1-1.9,2c-0.1-0.4-0.2-0.7-0.2-0.8c0.1,0.2,0.1,0.6,0.1,0.9c-0.3-0.4-0.6-0.7-0.6-0.7
		c0.3,0.2,0.4,0.5,0.4,0.9c-0.1,0-0.2,0.1-0.3,0.3c-0.1-1.1-0.3-2-0.3-2c-0.4,0.4-0.7,0.7-1,1c0.2-0.6,0.6-1.2,0.8-1.5
		c-0.4,0.4-0.6,0.8-0.9,1.2c0.1-1.3,0.4-2.2,0.6-2.8c-0.5,1.1-0.7,2.2-0.9,3.2c0,0.1-0.1,0.1-0.1,0.2c-0.3-1.3-1.1-2.2-1.1-2.2
		c0.8,0.9,0.8,1.9,0.5,2.8c0-0.8-0.1-1.6-0.3-2c0,0,0,1.1,0,2.2c-0.2-0.3-0.5-0.6-0.6-0.8c0.3,0.4,0.2,1.1,0,1.8
		C78.1,55,78,54.9,78,54.9c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.2-0.2,0.4-0.3,0.7c0.1-0.9-0.5-1.8-1-2.2c0-0.9,0.3-1.7,0.3-1.7
		c-0.9,1-1.4,1.8-1.5,2.5c-0.1-0.3-0.3-0.4-0.4-0.6c0-0.1,0-0.2-0.1-0.3c0,0.1,0,0.2,0,0.3c-0.1-0.1-0.2-0.2-0.2-0.3
		c0.2,0.2,0.2,0.5,0.2,0.8c-0.2-0.4-0.5-0.6-0.6-0.8c0.1,0.2,0.2,0.4,0.2,0.7c-0.3-0.2-0.5-0.3-0.8-0.4c0.2-0.8,0.7-1.5,0.9-1.9
		c-0.6,0.7-1,1.3-1.3,1.8c-0.4,0-0.7,0-0.7,0c0.2,0,0.5,0,0.7,0c-0.6,1.1-0.8,1.9-0.6,2.4c0,0,0,0,0,0c0-0.2-0.1-0.3-0.1-0.5
		c0,0,0,0,0,0c0,0,0,0,0,0c0-0.4,0-0.8,0-1.1c0.1,0,0.1-0.1,0.2-0.1c0,0-0.1,0-0.2,0.1c0-0.3,0.1-0.5,0.1-0.5
		c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1-0.8,0-1.5,0-2c0.1-0.2,0.3-0.4,0.3-0.5c-0.1,0.2-0.2,0.3-0.3,0.4c0.1-0.9,0.3-1.4,0.3-1.4
		c-0.3,0.5-0.5,1.1-0.6,1.8c-0.4,0.6-0.8,1.2-1,1.6c-0.2-1.1-0.2-2.3,0.6-3.1c0,0-0.6,0.6-1.2,1.4c0-0.1,0-0.3,0-0.4
		c0,0.1,0,0.3,0,0.4c-0.3,0.5-0.6,1-0.8,1.6c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.9-1.4-1.4-1.4-1.4c0.3,0.2,0.4,0.4,0.5,0.7
		c-0.3-0.2-0.5-0.3-0.5-0.3c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.5,0,1.2-0.2,1.8c0,0,0,0,0,0c-0.3-0.6-0.6-1-0.9-1.2
		c0.3,0.4,0.2,1.1,0,1.8c-0.2-0.1-0.3-0.1-0.3-0.1c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.3-0.2,0.5-0.3,0.8c0-0.1-0.1-0.2-0.1-0.3
		c0-0.4-0.1-0.9-0.4-1.4c-0.1-0.5,0-0.9,0.1-1.3c0,0-0.2,0.4-0.2,1.1c-0.1-0.3-0.3-0.6-0.6-0.8c0,0,0,0.1-0.1,0.4
		c-0.2-0.2-0.3-0.3-0.3-0.3c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.4-0.2,0.9-0.2,1.5c-0.1-0.3-0.2-0.7-0.4-0.9c0.1-0.4,0.2-0.6,0.2-0.6
		c-0.1,0.2-0.2,0.4-0.2,0.5c-0.5-0.8-1.2-1.4-1.2-1.4c0.6,0.4,0.6,1.2,0.4,2c0,0-0.1,0-0.1,0.1c-0.2-0.2-0.3-0.3-0.4-0.4
		c-0.2-0.8-0.1-1.6,0.8-2.2c0,0-0.5,0.4-1,0.9c0.1-0.7,0.1-1.3,0-1.8c0,0,0,0.8,0,1.9c-0.3,0.3-0.6,0.6-0.8,1c-0.1,0-0.1,0-0.2,0.1
		c0.1-1.2,0-2.2-0.1-2.7c0,0.7,0,1.7-0.1,2.7c-0.2-0.3-0.4-0.4-0.4-0.4c0.2,0.1,0.3,0.4,0.4,0.6c-0.2,0.1-0.3,0.3-0.3,0.3
		c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0,0.1,0,0.1c0,0.2,0,0.3-0.1,0.5c-0.1,0.2-0.2,0.5-0.2,0.7c0,0,0,0-0.1,0c0.1-1.4-0.2-2.8-0.2-2.8
		c-0.3,0.3-0.5,0.5-0.7,0.7c0-0.1,0-0.2,0-0.3c0.2-0.4,0.4-0.7,0.6-0.9c-0.2,0.3-0.4,0.5-0.6,0.7c0.1-2.1,0.8-3.2,0.8-3.2
		c-0.6,0.7-0.8,2-0.9,3.4c-0.2,0.3-0.4,0.6-0.5,0.8C60.6,54,60.2,53.4,60,53c0.1-0.8-0.1-1.1-0.1-1.1c0,0.3,0,0.6-0.1,0.9
		c-0.1-0.1-0.1-0.1-0.1-0.1c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.7-0.2,1c-0.1-0.5-0.2-1.3-0.1-1.9c0,0-0.4,0.8-0.1,2.3
		c-0.3,0.7-0.3,1.6-0.2,2.4c0,0,0,0,0,0.1c-0.1-0.1-0.2-0.1-0.2-0.1c0.1,0,0.2,0.1,0.2,0.1c0,0.1,0,0.2-0.1,0.3
		c-0.1,0.1-0.1,0.3-0.2,0.4c0.1-0.9-0.5-1.8-0.9-2.2c0-0.9,0.3-1.7,0.3-1.7c-0.2,0.2-0.4,0.5-0.6,0.7c0-1.3-0.1-2.5-0.6-3.1
		c0,0,0.6,1,0.4,3.3c-0.2,0.2-0.3,0.5-0.4,0.7c0,0,0,0,0,0c0-0.7,0.2-1.3,0.2-1.3c-0.5,0.5-0.9,1.1-1.1,1.6c0-0.7,0-1.4,0.1-2
		c0,0,0-0.1,0.1-0.1c0,0,0,0-0.1,0.1c0-0.2,0-0.5,0.1-0.7c0,0.2-0.1,0.5-0.2,0.8c0,0,0,0.1-0.1,0.1c0,0,0.1-0.1,0.1-0.1
		c-0.1,0.5-0.2,1.2-0.2,2c-0.2,0-0.5,0-0.8,0.2c0.2-0.9,0.7-1.7,1-2.1c-0.5,0.6-0.8,1.1-1.1,1.6c0-0.1,0-0.2,0-0.3
		c0,0.1,0,0.3,0,0.4c-0.2,0.4-0.4,0.7-0.5,1c-0.1,0.1-0.3,0.3-0.4,0.5c0-0.1,0-0.2,0-0.2c0.1,0,0.1-0.1,0.2-0.1c0,0-0.1,0.1-0.2,0.1
		c0-0.3,0.1-0.5,0.1-0.5c-0.3,0.3-0.5,0.6-0.7,0.9c-0.1,0.1-0.1,0.1-0.2,0.2c0-0.3,0-0.6-0.1-0.9c0.1-0.9,0.7-1.8,1-2.2
		c-0.8,1-1.3,1.8-1.6,2.5c-0.2-1.1-0.2-2.3,0.6-3.1c0,0-1.5,1.4-2,3.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5-0.9-1.4-1.4-1.4-1.4
		c0.2,0.2,0.4,0.4,0.5,0.7c-0.3-0.2-0.5-0.3-0.5-0.3c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.4,0,1-0.2,1.5c0-0.5,0-1.1,0.1-1.5
		c0,0-0.1,0.7-0.2,1.6c-0.2-0.5-0.6-0.9-0.8-1.1c0.2,0.3,0.2,0.7,0.2,1.1c-0.1-0.1-0.2-0.2-0.3-0.3c0,0,0.1,0.3,0.2,0.7
		c0,0.1-0.1,0.3-0.1,0.4c-0.2-0.1-0.3-0.1-0.3-0.1c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.4-0.3,0.7-0.4,1c0-0.6,0-1.3-0.4-2.1
		c0-0.7,0.1-1.4,0.2-2.1c-0.1,0.5-0.3,1.1-0.4,1.8c-0.1-0.2-0.2-0.4-0.4-0.5c0,0-0.2,0.7-0.3,1.5c-0.2-0.4-0.5-0.7-0.7-1
		c0.1-1,0-2-0.4-2.7c0,0,0.3,0.9,0.2,2.4c-0.1,0-0.1-0.1-0.1-0.1c0,0,0.1,0.1,0.1,0.1c0,0.4-0.1,0.8-0.2,1.3c-0.1-0.4-0.3-0.7-0.5-1
		c0.1,0,0.2-0.1,0.3-0.1c0,0-0.1,0-0.3,0c-0.2-0.3-0.5-0.5-0.6-0.7c0.2,0.2,0.2,0.5,0.2,0.8c-0.2,0.1-0.5,0.2-0.8,0.4
		c-0.1-0.7,0.1-1.4,0.8-2c0,0-0.7,0.6-1.4,1.4c0.1-0.2,0.1-0.3,0.2-0.5c0,0-0.1,0.2-0.2,0.5c-0.2,0.3-0.5,0.6-0.7,0.9
		c-0.3-0.4-0.6-0.7-0.6-0.7c0.3,0.2,0.4,0.6,0.4,1.1c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.3,0-0.4c0.1-0.2,0.2-0.3,0.2-0.3
		c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1-0.9-0.2-1.5-0.2-1.5c-0.4,0.4-0.7,0.7-1,1.1c0.2-0.7,0.6-1.3,0.9-1.6c-0.5,0.7-1,1.3-1.3,1.8
		c-0.2-1.3-1-2.1-1-2.1c0.9,1,0.7,2.1,0.3,3.2c0,0,0-0.1-0.1-0.1c0.1-1.2,0.2-2.3,0.2-2.3c-0.2,0.5-0.4,1.2-0.5,2
		c-0.2-0.2-0.3-0.4-0.4-0.5c0.3,0.4,0.2,1.1-0.1,1.8c-0.1,0-0.1-0.1-0.2-0.1c0.1-1.3,0.1-3-0.4-4.6c0.2,0.8,0.3,2.1,0.2,4
		c0,0-0.1,0-0.2,0.1c-0.2-0.4-0.4-0.6-0.6-0.8c0.1-0.9,0.4-1.7,0.4-1.7c-0.6,0.6-0.9,1.1-1.2,1.6c0.1-0.8,0.2-1.3,0.2-1.3
		c-0.8,0.8-1.3,1.8-1.6,2.6c-0.1,0.1-0.2,0.2-0.4,0.3c0-0.4-0.1-0.8-0.2-1.3c0.2-0.7,0.7-1.4,0.9-1.8c-0.4,0.5-0.8,1-1,1.5
		c-0.1-0.2-0.1-0.4-0.3-0.6c0.3-0.7,0.7-1.4,1.4-2c0,0-0.7,0.5-1.4,1.9c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0.5-0.5,1.2-0.7,2.1
		c0,0,0,0-0.1,0.1c0-0.3,0.1-0.5,0.1-0.5c-0.3,0.3-0.5,0.6-0.7,0.9c0,0-0.1,0-0.1,0.1c-0.1-0.2-0.1-0.4-0.2-0.5c0-1,0.7-2.1,1.1-2.6
		c-0.6,0.8-1.1,1.5-1.4,2.1c0.3-1.1,0.8-2.2,1.8-3.1c0,0-1,0.8-1.8,2.5c-0.2,0.2-0.5,0.4-0.8,0.7c-0.2-0.3-0.4-0.4-0.4-0.4
		c0.2,0.1,0.3,0.3,0.3,0.5c-0.2,0.2-0.4,0.4-0.5,0.7c-0.2-0.2-0.3-0.4-0.5-0.6c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.2-0.1-0.3-0.1-0.5
		c0.1-0.5,0.3-0.9,0.5-1.2c0,0-0.2,0.2-0.5,1.1c-0.1-0.4-0.3-0.6-0.4-0.8c0.1-0.1,0.2-0.2,0.3-0.3c0,0-0.1,0.1-0.3,0.3
		C30.3,57,30.1,57,30.1,57c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.4C30,57.6,30,57.5,30,57.5c0,0,0.1,0.1,0.1,0.1
		c-0.1,0.1-0.2,0.3-0.3,0.5c-0.2-0.8-0.3-1-0.3-1c0.2,0.2,0.3,0.6,0.3,1c-0.1,0.2-0.2,0.5-0.3,0.9c-0.1-0.1-0.1-0.1-0.2-0.2
		c0.1,0.1,0.1,0.2,0.1,0.3c-0.1,0.4-0.2,0.9-0.3,1.5 M2.8,64.9c0,0,0-0.1-0.1-0.1c0-0.1,0-0.1,0.1-0.2C2.8,64.7,2.8,64.8,2.8,64.9z
		 M3.1,62.9c0,0.1,0.1,0.1,0.1,0.2c0,0-0.1,0-0.1-0.1C3.1,63,3.1,62.9,3.1,62.9z M3.1,64.5c0-0.1,0-0.2,0-0.4
		c0.1-0.1,0.1-0.2,0.2-0.3C3.2,64.1,3.1,64.3,3.1,64.5z M3,64c0-0.3,0-0.6,0.1-0.9c0,0,0.1,0.1,0.1,0.1c0,0.2,0,0.4,0,0.6
		C3.2,63.8,3.1,63.9,3,64z M4.3,64C4.3,64,4.3,63.9,4.3,64C4.3,63.9,4.3,63.9,4.3,64C4.3,63.9,4.3,63.9,4.3,64z M7.1,63.8
		C7.2,63.8,7.2,63.8,7.1,63.8c0,0,0,0.1,0,0.2C7.1,63.9,7.1,63.9,7.1,63.8z M8.4,64.6c-0.1-0.1-0.1-0.2-0.2-0.4
		c0.1-0.1,0.2-0.2,0.3-0.3C8.5,64.2,8.4,64.4,8.4,64.6z M10.3,62.2C10.3,62.2,10.3,62.2,10.3,62.2C10.3,62.2,10.3,62.2,10.3,62.2
		C10.3,62.2,10.3,62.2,10.3,62.2z M11.3,61.1c0.1,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.1-0.1-0.2-0.1C11.2,61.3,11.3,61.2,11.3,61.1z
		 M11.4,62.6c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.3,0.1-0.6,0.2-0.9c0.1,0.1,0.2,0.1,0.2,0.2C11.5,61.9,11.5,62.3,11.4,62.6z M15.6,62.2
		c-0.1,0.3-0.2,0.5-0.3,0.8c0-0.3,0-0.5,0.1-0.7C15.5,62.2,15.5,62.2,15.6,62.2c0-0.1-0.1,0-0.2,0.1c0-0.3,0.1-0.5,0.1-0.5
		c-0.1,0.1-0.1,0.2-0.2,0.2c0-0.2,0-0.4,0-0.6c0.1,0,0.2,0,0.3,0C15.6,61.7,15.7,61.9,15.6,62.2z M15.9,61.7
		C15.9,61.7,15.9,61.7,15.9,61.7c-0.1,0-0.2-0.1-0.2-0.1C15.8,61.5,15.9,61.5,15.9,61.7C16,61.6,16,61.6,15.9,61.7z M16.7,60.7
		C16.7,60.6,16.7,60.6,16.7,60.7C16.7,60.6,16.7,60.6,16.7,60.7C16.7,60.6,16.7,60.7,16.7,60.7z M20.4,62.3
		C20.4,62.3,20.4,62.2,20.4,62.3C20.4,62.2,20.5,62.2,20.4,62.3C20.5,62.2,20.4,62.3,20.4,62.3z M20.5,62.2
		C20.5,62.2,20.5,62.2,20.5,62.2c0-0.6,0.1-1.2,0.1-1.8C20.8,60.8,20.7,61.5,20.5,62.2z M21.3,61c0.1-0.6,0.1-1.2,0.2-1.7
		C21.7,59.8,21.6,60.4,21.3,61z M23.6,61.9c0,0.1,0,0.1-0.1,0.2c0-0.1,0-0.3,0-0.4c0,0,0,0,0.1,0C23.6,61.7,23.6,61.8,23.6,61.9z
		 M25.6,58.6C25.6,58.6,25.6,58.6,25.6,58.6c0,0.1,0,0.1-0.1,0.1C25.5,58.7,25.5,58.6,25.6,58.6z M25.8,61.2
		C25.8,61.2,25.8,61.2,25.8,61.2c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.1,0,0.1,0.1C25.8,61,25.8,61.1,25.8,61.2z M25.8,60.9
		c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.2-0.1C25.8,60.8,25.8,60.9,25.8,60.9z M25.6,60.8c-0.2-0.6-0.3-1.3,0-2
		c0.1,0.6,0.2,1.3,0.2,1.9C25.7,60.7,25.7,60.7,25.6,60.8z M26,60.7c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0C26,60.6,26,60.7,26,60.7z
		 M27.3,61.3c0-0.1,0-0.2,0-0.3c0,0,0.1,0,0.1,0.1C27.3,61.2,27.3,61.2,27.3,61.3z M29.3,60.5c0-0.3,0.1-0.7,0.1-1.1
		C29.5,59.7,29.5,60.1,29.3,60.5z M30.1,57.7c0.1,0.1,0.2,0.3,0.3,0.5C30.2,58.1,30.1,58,30,58C30,57.9,30.1,57.8,30.1,57.7z
		 M29.8,59.6c-0.1-0.1-0.2-0.3-0.3-0.4c0.1-0.3,0.1-0.5,0.3-0.8C29.8,58.7,29.8,59.1,29.8,59.6z M30.2,60.1
		C30.1,60.1,30.1,60.1,30.2,60.1C30.1,60,30,59.9,30,59.8c0-0.7-0.1-1.2-0.2-1.5c0-0.1,0.1-0.2,0.2-0.3c0.2,0.1,0.3,0.2,0.4,0.4
		C30.5,58.8,30.4,59.5,30.2,60.1z M30.1,57.7c0.1-0.1,0.2-0.2,0.3-0.4c0.2,0.2,0.4,0.5,0.4,0.8c0,0,0,0.1,0,0.2
		C30.6,58,30.3,57.8,30.1,57.7z M30.9,58.3c0,0,0-0.1,0-0.1C30.9,58.2,30.9,58.3,30.9,58.3C30.9,58.4,30.9,58.3,30.9,58.3z
		 M32.8,58.9c0-0.3,0.1-0.5,0.3-0.8C33,58.4,32.9,58.7,32.8,58.9z M33.6,59.8c0-0.2-0.1-0.3-0.1-0.5C33.5,59.5,33.6,59.6,33.6,59.8
		C33.6,59.8,33.6,59.8,33.6,59.8z M33.6,59.9C33.6,59.9,33.6,59.9,33.6,59.9C33.6,59.9,33.6,59.9,33.6,59.9
		C33.6,59.9,33.6,59.9,33.6,59.9z M34.4,59.7c0-0.2,0-0.4,0-0.5c0,0,0.1,0,0.1-0.1C34.5,59.3,34.4,59.5,34.4,59.7z M34.6,59.4
		c0.1-0.7,0.3-1.6,0.6-2.4c0,0.1,0.1,0.4,0.2,0.7C35,58.4,34.7,59,34.6,59.4z M35.5,58.3c0.1,0.3,0.1,0.7,0.1,1.1
		c0,0-0.1,0.1-0.1,0.1C35.4,59.1,35.4,58.7,35.5,58.3z M35.6,59.8c0,0-0.1-0.1-0.1-0.1c0,0,0.1-0.1,0.1-0.1
		C35.6,59.6,35.6,59.7,35.6,59.8z M35.9,60.2c0,0-0.1-0.1-0.1-0.1c0-0.2,0-0.5,0-0.7c0.1-0.1,0.2-0.2,0.4-0.3
		C36,59.4,35.9,59.8,35.9,60.2z M39.1,59c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.1-0.1,0.2-0.1C39.1,58.7,39.1,58.8,39.1,59z M39.3,59.9
		c0-0.2,0-0.4,0.1-0.7c0.1,0,0.1,0.1,0.2,0.1C39.4,59.5,39.4,59.7,39.3,59.9z M42.6,59c0,0.1,0,0.1-0.1,0.2c0-0.1,0-0.3,0-0.4
		c0,0,0,0,0.1,0C42.6,58.9,42.6,58.9,42.6,59z M42.7,58.8C42.6,58.8,42.6,58.8,42.7,58.8c0-0.3,0-0.6,0-0.8c0.1,0,0.2,0,0.3,0
		C42.8,58.2,42.7,58.5,42.7,58.8z M44.9,58.4c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2,0.1,0.2,0.1C44.9,58.3,44.9,58.3,44.9,58.4z M45,58.2
		c-0.1,0-0.2-0.1-0.2-0.1c0,0,0-0.1,0-0.1c0.1-0.1,0.2-0.2,0.4-0.2C45.1,57.9,45,58,45,58.2z M45.1,57.8c-0.1,0-0.2,0.1-0.4,0.2
		c-0.1-0.3-0.2-0.5-0.2-0.8c0.2-0.2,0.5-0.3,0.8-0.4C45.2,57.1,45.2,57.4,45.1,57.8z M46.3,58.5c0-0.1,0-0.2,0-0.3
		c0,0,0.1,0,0.1,0.1C46.4,58.4,46.4,58.5,46.3,58.5z M46.4,58.3c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0.1-0.5,0.2-1,0.3-1.5
		C46.7,57.1,46.6,57.7,46.4,58.3z M55.7,57.6c-0.2-0.2-0.3-0.4-0.4-0.6c0.2-0.2,0.4-0.5,0.6-0.6C55.8,56.9,55.7,57.3,55.7,57.6z
		 M56,56.1c0,0.1-0.1,0.2-0.1,0.3c-0.3,0.2-0.5,0.4-0.7,0.5c0-0.1-0.1-0.2-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.3,0-0.4
		c0.2-0.1,0.4-0.2,0.7-0.2c0,0,0.1,0,0.1,0C56,55.9,56,56,56,56.1z M59.6,55.2C59.5,55.2,59.5,55.2,59.6,55.2
		c-0.1-0.2-0.1-0.3-0.1-0.3c0-0.1,0-0.1,0.1-0.2c0,0.1,0,0.1,0,0.2C59.6,54.8,59.6,55,59.6,55.2z M60.1,55.9
		c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.5,0-0.9-0.1-1.3c0.2-0.5,0.2-0.9,0.3-1.2C60.6,53.9,60.5,55,60.1,55.9z M61.3,54.5
		c0,0-0.1,0.1-0.1,0.1c0-0.1,0.1-0.2,0.1-0.3C61.3,54.4,61.3,54.4,61.3,54.5z M62.4,56.7c0,0.1,0,0.1-0.1,0.2c0-0.1,0-0.3,0-0.4
		c0,0,0,0,0.1,0C62.4,56.5,62.4,56.6,62.4,56.7z M63,55.1C63,55.1,63,55,63,55.1C63,55,63.1,55,63,55.1C63.1,55,63,55.1,63,55.1z
		 M64.4,55.5c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0C64.5,55.6,64.4,55.6,64.4,55.5z M64.5,55.7C64.5,55.7,64.5,55.7,64.5,55.7
		C64.5,55.7,64.5,55.8,64.5,55.7C64.5,55.8,64.5,55.8,64.5,55.7C64.5,55.8,64.5,55.7,64.5,55.7z M64.5,55.8
		C64.5,55.8,64.5,55.8,64.5,55.8C64.6,55.8,64.6,55.9,64.5,55.8C64.6,55.9,64.5,55.8,64.5,55.8z M64.8,55.7
		C64.8,55.6,64.8,55.6,64.8,55.7c0-0.1,0-0.1,0.1-0.2C64.9,55.5,64.9,55.6,64.8,55.7z M72.3,55.4C72.3,55.4,72.3,55.4,72.3,55.4
		c0-0.1,0-0.1,0.1-0.1C72.4,55.3,72.3,55.4,72.3,55.4z M72.5,55c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2-0.7,0-1.5,0.3-2.2
		C72.5,53.7,72.5,54.4,72.5,55C72.5,55,72.5,55,72.5,55z M74.5,55.7C74.5,55.7,74.5,55.7,74.5,55.7c0-0.2,0.1-0.3,0.1-0.4
		c0,0,0,0,0,0C74.6,55.5,74.6,55.6,74.5,55.7z M74.7,55.3C74.7,55.3,74.7,55.3,74.7,55.3C74.7,55.3,74.7,55.3,74.7,55.3
		C74.7,55.3,74.7,55.3,74.7,55.3z M74.8,55c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.1,0-0.1,0c0,0,0.1,0,0.1,0c-0.1,0.1-0.1,0.1-0.2,0.2
		c0-0.1-0.1-0.1-0.1-0.2c0.2-0.3,0.5-0.5,0.7-0.7C74.9,54.7,74.8,54.8,74.8,55z M74.9,54.5c-0.3,0.2-0.5,0.4-0.7,0.5
		c-0.1-0.4-0.1-0.9,0-1.3c0.3,0.1,0.6,0.2,0.8,0.4C74.9,54.3,74.9,54.4,74.9,54.5z M81.3,55.2c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0
		C81.3,55.1,81.3,55.2,81.3,55.2z M81.4,54.8C81.4,54.8,81.3,54.8,81.4,54.8C81.3,54.8,81.3,54.8,81.4,54.8
		C81.3,54.8,81.4,54.8,81.4,54.8C81.4,54.8,81.4,54.8,81.4,54.8z M81.7,53.9c-0.1,0.3-0.2,0.6-0.3,0.8c0,0,0,0-0.1,0
		c0-0.3,0-0.5,0-0.8l0,0c0,0,0.2-0.2,0.3-0.3C81.7,53.8,81.7,53.9,81.7,53.9z M83.9,51.4c0.1,0.1,0.1,0.3,0.2,0.4
		c-0.1,0.2-0.2,0.3-0.2,0.5c-0.2-0.2-0.4-0.4-0.4-0.4c0.2,0.1,0.3,0.3,0.4,0.5c-0.2,0.5-0.4,1-0.5,1.5C83,53,83,52,83.9,51.4z
		 M83.6,54.6c-0.1-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2,0.1,0.2,0.1C83.7,54.4,83.6,54.5,83.6,54.6z M83.7,54.3c-0.1,0-0.2-0.1-0.2-0.1
		c0,0,0,0,0,0c0,0,0,0,0-0.1c0.1-0.1,0.2-0.1,0.4-0.2C83.8,54.1,83.7,54.2,83.7,54.3z M83.8,53.9c-0.1,0-0.2,0.1-0.4,0.2
		c0.1-0.6,0.2-1.1,0.4-1.6C84,52.9,84,53.4,83.8,53.9z M83.9,52.4c0.1-0.2,0.2-0.4,0.2-0.5c0.1,0.3,0.2,0.7,0.3,1.1
		C84.2,52.7,84,52.5,83.9,52.4z M85.1,54.5C85.1,54.5,85.1,54.6,85.1,54.5C85.2,54.6,85.1,54.6,85.1,54.5
		C85.1,54.6,85.1,54.5,85.1,54.5z M85.2,54.6C85.2,54.6,85.2,54.6,85.2,54.6C85.2,54.6,85.2,54.6,85.2,54.6
		C85.2,54.6,85.2,54.6,85.2,54.6C85.2,54.6,85.2,54.6,85.2,54.6z M85.3,54c-0.1,0.2-0.1,0.3-0.2,0.5c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.2-0.6-0.2-1.3,0.1-1.7C85.4,53.1,85.4,53.6,85.3,54z M86.7,54.3c0-0.1,0-0.1-0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1
		C86.9,54.1,86.8,54.2,86.7,54.3z M86.9,54c-0.1,0-0.1,0.1-0.2,0.1c0-0.1-0.1-0.3-0.1-0.4C86.7,53.8,86.9,53.8,86.9,54
		C86.9,53.9,86.9,54,86.9,54z M88.2,52.6c0-0.2,0.1-0.4,0.2-0.6c0,0.3,0.1,0.6,0.1,1C88.4,52.9,88.3,52.7,88.2,52.6z M89,54.4
		c0,0.1,0,0.1,0,0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3c0,0,0,0,0.1,0C89,54.3,89,54.4,89,54.4z M89,54.3C89,54.3,89,54.3,89,54.3
		c-0.1-0.3-0.1-0.5-0.3-0.8c0-0.5,0.1-0.9,0.5-1.2c0,0-0.3,0.3-0.6,0.7c-0.1-0.4-0.1-0.8-0.2-1c0.1-0.3,0.3-0.6,0.6-0.9
		c0.2,0.2,0.3,0.4,0.4,0.7C89.3,52.2,89,53.2,89,54.3z M89.6,51.5c0.1-0.2,0.1-0.3,0.2-0.5c0.2,0.3,0.5,0.8,0.6,1.3
		C90.2,52,89.9,51.7,89.6,51.5z M92,52.8c0,0,0.3,0.5,0.4,1.2c0,0-0.1,0.1-0.3,0.2C92,53.7,91.9,53.2,92,52.8z M92.3,54.8
		c-0.1-0.2-0.2-0.4-0.2-0.6c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1,0,0.2,0,0.2C92.4,54.4,92.3,54.6,92.3,54.8z M93.8,51.4c0,0,0-0.1,0-0.1
		c0,0.1,0.1,0.2,0.1,0.4C93.9,51.5,93.8,51.5,93.8,51.4z M95.7,54c0-0.2-0.1-0.5-0.1-0.7c0,0,0.1,0.1,0.2,0.1
		C95.7,53.5,95.7,53.7,95.7,54z M96,54c0-0.2,0-0.3,0-0.5c0.1,0.1,0.1,0.1,0.2,0.2C96.1,53.9,96,54,96,54z M97.3,54.1
		c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.2-0.1-0.4c0.1,0,0.1-0.1,0.2-0.1C97.3,53.6,97.3,53.8,97.3,54.1z M97.9,53.4c0-0.1-0.1-0.1-0.1-0.2
		c0,0,0.1,0,0.1,0C97.9,53.2,97.9,53.3,97.9,53.4z M98.1,53.6C98.1,53.6,98.1,53.6,98.1,53.6c0-0.6,0-1.3-0.1-2
		C98.3,52.2,98.4,52.9,98.1,53.6z M101.6,53.2C101.6,53.2,101.6,53.2,101.6,53.2c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2,0,0.3,0
		C101.7,53,101.7,53.1,101.6,53.2z M102.5,51.9c-0.1,0.3-0.1,0.6-0.2,1C102.3,52.6,102.3,52.2,102.5,51.9z M102.4,53.3
		c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0,0C102.5,53.2,102.5,53.2,102.4,53.3z M102.5,52.9C102.5,52.9,102.4,52.9,102.5,52.9
		c-0.1-0.3,0-0.6,0-0.9c0-0.1,0.1-0.1,0.1-0.2C102.6,52,102.5,52.4,102.5,52.9z M103.4,52.6c-0.1-0.1-0.1-0.2-0.2-0.3
		c0.1-0.1,0.3-0.2,0.5-0.2C103.6,52.2,103.5,52.4,103.4,52.6z M104.1,51.7c0.1,0.1,0.1,0.1,0.1,0.2c0,0-0.1,0-0.1,0
		C104.1,51.8,104.1,51.8,104.1,51.7z M104.3,53.5c0-0.6-0.1-1.2-0.1-1.6c0,0,0.1,0,0.1,0C104.5,52.3,104.4,52.9,104.3,53.5z
		 M110.9,53.9C110.9,53.9,110.9,53.8,110.9,53.9c0-0.1,0-0.2,0-0.3C111,53.7,111,53.8,110.9,53.9z M111,53.4
		c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.2,0.1-0.3,0.1-0.5c0.1-0.1,0.3-0.2,0.4-0.3C111.1,52.9,111,53.2,111,53.4z
		 M113.4,52.4c0-0.2,0-0.3,0-0.4C113.4,52.1,113.4,52.2,113.4,52.4C113.4,52.4,113.4,52.4,113.4,52.4z M113.8,52.4
		c0-0.1-0.1-0.1-0.1-0.2c0,0,0.1-0.1,0.1-0.1C113.8,52.2,113.8,52.3,113.8,52.4z M114.6,53.4c-0.2-0.1-0.3-0.1-0.3-0.1
		C114.4,53.3,114.5,53.3,114.6,53.4c-0.1,0.2-0.2,0.5-0.3,0.7c0-0.4-0.1-0.9-0.3-1.2c0-0.2-0.1-0.5-0.1-0.7c0.2-0.1,0.5-0.2,0.8-0.1
		c0,0,0,0,0,0C114.8,52.4,114.7,52.9,114.6,53.4z M115.3,52.5c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1,0-0.2,0-0.2c0.1,0,0.2,0.1,0.3,0.2
		c0,0-0.1-0.1-0.3-0.3c0-0.8-0.1-1.6,0-2.3C115.7,50.6,115.7,51.6,115.3,52.5z M116.5,51.2c0,0.1-0.1,0.1-0.1,0.2
		c0-0.1,0.1-0.3,0.2-0.4C116.5,51,116.5,51.1,116.5,51.2z M117.7,53.5c0,0.1,0,0.1-0.1,0.2c0-0.1,0-0.3,0-0.4c0,0,0,0,0.1,0
		C117.7,53.4,117.7,53.4,117.7,53.5z M118.4,51.8C118.4,51.8,118.3,51.8,118.4,51.8c-0.1-0.2-0.1-0.4-0.1-0.4c0-0.1,0-0.1,0.1-0.2
		c0,0.1,0,0.1,0,0.2C118.4,51.4,118.4,51.5,118.4,51.8z M119.8,52.9c0,0,0-0.1-0.1-0.1c0,0,0.1-0.1,0.1-0.1
		C119.9,52.7,119.8,52.8,119.8,52.9z M119.7,52.7c-0.4-1-0.5-2,0.4-2.6c0,0.6,0,1.3,0,1.9c-0.1,0.2-0.2,0.4-0.2,0.6
		C119.8,52.7,119.8,52.7,119.7,52.7z M120.1,52.5c0-0.1,0-0.1,0-0.1C120.1,52.4,120.1,52.4,120.1,52.5
		C120.1,52.5,120.1,52.5,120.1,52.5z M122.2,52.3c-0.1-0.2-0.2-0.3-0.3-0.4c0.1,0,0.1,0,0.1,0c0.2,0,0.3,0,0.5,0
		C122.3,52,122.3,52.1,122.2,52.3z M122.6,52.8c-0.1-0.3-0.1-0.6,0-0.9c0,0,0.1,0,0.1,0.1C122.7,52.3,122.7,52.5,122.6,52.8z
		 M122.8,51.8c0,0-0.1,0-0.1,0c0-0.1,0.1-0.2,0.1-0.3C122.8,51.6,122.8,51.7,122.8,51.8z M122.9,53.2
		C122.9,53.2,122.9,53.2,122.9,53.2C122.9,53.2,122.9,53.2,122.9,53.2C122.9,53.2,122.9,53.2,122.9,53.2z M122.9,53.2
		C122.9,53.2,122.9,53.2,122.9,53.2c0-0.4,0-0.8,0-1.2c0.1,0.1,0.2,0.2,0.3,0.3C123,52.6,122.9,52.9,122.9,53.2z M124.4,53
		c0,0.1-0.1,0.1-0.1,0.2c0-0.1,0-0.1-0.1-0.2c0,0,0.1-0.1,0.2-0.1C124.4,52.9,124.4,53,124.4,53z M124.4,52.9c0,0-0.1,0-0.2,0.1
		c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.2,0.1,0.3,0.1C124.4,52.7,124.4,52.8,124.4,52.9z M124.5,52.7C124.5,52.7,124.5,52.7,124.5,52.7
		C124.6,52.7,124.6,52.7,124.5,52.7C124.6,52.7,124.6,52.7,124.5,52.7z M126.6,53.5c0-0.1,0-0.2,0-0.3c0,0,0,0,0.1,0
		c0,0.1,0,0.3,0,0.4C126.6,53.6,126.6,53.5,126.6,53.5z M127.9,51.2c0-0.1,0-0.1,0-0.1C127.9,51.2,127.9,51.2,127.9,51.2
		C128,51.3,127.9,51.3,127.9,51.2z M129.7,53.3C129.7,53.3,129.7,53.3,129.7,53.3C129.7,53.3,129.7,53.3,129.7,53.3
		C129.7,53.3,129.7,53.3,129.7,53.3z M129.7,53.3C129.7,53.3,129.7,53.3,129.7,53.3c-0.2-0.7-0.3-1.4,0.1-1.8
		C129.8,52.1,129.8,52.7,129.7,53.3z M130.1,53.1c0-0.4,0-0.8-0.1-1.2c0.1,0.2,0.3,0.4,0.4,0.6C130.3,52.7,130.2,52.9,130.1,53.1z
		 M133.2,52.6c0.2,0.1,0.3,0.3,0.5,0.4c-0.1,0.1-0.2,0.2-0.3,0.3C133.3,53.1,133.2,52.9,133.2,52.6z M133.4,53.7
		c0.1-0.2,0.2-0.4,0.3-0.5c0,0,0,0,0,0C133.7,53.3,133.6,53.5,133.4,53.7z M133.9,52.8c-0.1,0-0.1,0.1-0.2,0.1
		c-0.2-0.1-0.3-0.2-0.6-0.4c-0.1-0.3-0.2-0.6-0.3-0.9c0.5,0.1,0.8,0.4,1.1,0.7C133.9,52.5,133.9,52.7,133.9,52.8z M134.9,53.6
		C134.9,53.6,134.9,53.6,134.9,53.6C134.9,53.6,134.9,53.6,134.9,53.6c0,0.1,0,0.1,0,0.1C134.9,53.7,134.8,53.7,134.9,53.6
		c0-0.2-0.1-0.6-0.2-1c0.1,0,0.2,0,0.3-0.1C134.9,52.8,134.9,53.2,134.9,53.6z M135.7,53.2c0,0,0-0.1,0-0.1
		C135.6,53.1,135.7,53.2,135.7,53.2C135.7,53.2,135.7,53.2,135.7,53.2z M139.2,52.8c0,0,0.1,0,0.1,0
		C139.3,52.9,139.2,52.9,139.2,52.8C139.2,52.9,139.2,52.9,139.2,52.8z M139.2,53C139.3,52.9,139.3,52.9,139.2,53
		c0.1-0.1,0.2,0,0.2,0C139.4,53,139.3,53,139.2,53C139.2,53,139.2,53,139.2,53z M139.5,53.4c0,0,0,0.1-0.1,0.1
		c-0.1-0.1-0.1-0.3-0.2-0.5c0.1,0,0.1,0,0.2-0.1c0,0,0,0,0.1,0C139.5,53.1,139.5,53.3,139.5,53.4z M139.4,52.8c0,0,0.1,0,0.1,0
		c0,0,0,0.1,0,0.1C139.5,52.9,139.5,52.9,139.4,52.8z M140.5,54.4c0-0.2,0-0.4-0.1-0.5c0,0,0.1,0.1,0.1,0.1
		C140.5,54.1,140.5,54.2,140.5,54.4z M142.5,54.5c0-0.1,0-0.1,0-0.2c0,0,0,0,0.1,0C142.5,54.4,142.5,54.5,142.5,54.5z M142.9,53.5
		c-0.2-0.1-0.3-0.1-0.3-0.1C142.7,53.4,142.8,53.5,142.9,53.5c-0.1,0.3-0.2,0.6-0.3,0.8c0,0-0.1,0-0.1,0c0-0.3-0.1-0.6-0.1-0.9
		c0-0.7,0.2-1.4,0.3-1.9C143.1,52,143.1,52.8,142.9,53.5z M142.6,51.6c0.1-0.4,0.2-0.7,0.3-0.8c0.2,0.5,0.3,1.1,0.4,1.6
		C143,52,142.8,51.7,142.6,51.6z M148,52.7c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0C148,52.8,148,52.7,148,52.7z M147.5,50.8
		C147.5,50.8,147.5,50.8,147.5,50.8c0,0.1-0.1,0.2-0.1,0.2C147.4,50.9,147.4,50.9,147.5,50.8z M147.3,53.6
		C147.3,53.5,147.3,53.5,147.3,53.6c0-0.1,0-0.1,0-0.1C147.3,53.5,147.3,53.5,147.3,53.6z M147.4,53.2c-0.1,0-0.1,0.1-0.2,0.1
		c-0.3-0.7-0.2-1.3,0.1-1.9c0.1,0.5,0.1,1,0.2,1.5C147.4,53.1,147.4,53.1,147.4,53.2z M147.3,51.4c0.1-0.2,0.1-0.3,0.2-0.5
		c0.1,0.1,0.1,0.2,0.2,0.4c-0.1,0.3-0.1,0.6-0.2,1.1C147.4,52,147.4,51.7,147.3,51.4z M147.6,52.9c0-0.5,0-1.1,0.1-1.6
		c0.1,0.4,0.2,0.7,0.3,1.1C147.8,52.6,147.7,52.8,147.6,52.9z M148,52.8C148,52.8,148,52.8,148,52.8c0.1,0.3,0.1,0.7,0,1
		C148,53.5,148,53.1,148,52.8z M149.5,54.1c0,0-0.1-0.1-0.1-0.1c0,0,0.1,0,0.1,0C149.5,54,149.5,54.1,149.5,54.1z M149.6,53.7
		c-0.1,0-0.2,0-0.3,0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.2,0.3-0.3,0.4-0.4C149.6,53.3,149.6,53.5,149.6,53.7z M151.9,52.2
		c0-0.3,0-0.6,0.1-0.9c0-0.1,0.1-0.2,0.1-0.3c0.1,0.3,0.5,1.2,0.6,2.2C152.4,52.8,152.1,52.5,151.9,52.2z M156.4,53.6
		c0-0.4,0-0.9-0.1-1.4c0.2,0.3,0.3,0.6,0.2,1C156.5,53.3,156.5,53.5,156.4,53.6z M158.6,54.1c-0.1-0.1-0.1-0.2-0.2-0.3
		c0.1,0,0.2,0.1,0.2,0.1C158.6,53.9,158.6,54,158.6,54.1z M158.7,53.9c-0.1-0.1-0.2-0.1-0.2-0.1c0,0,0-0.1,0-0.1
		c0.1-0.1,0.2-0.1,0.4-0.2C158.7,53.6,158.7,53.7,158.7,53.9z M160.3,53.9c0-0.1-0.1-0.3-0.1-0.4c0,0,0.1,0,0.1,0
		C160.3,53.6,160.3,53.8,160.3,53.9z M160.2,53.4c0-0.2,0-0.5,0-0.6c0.1,0.2,0.1,0.4,0.1,0.7C160.3,53.5,160.2,53.5,160.2,53.4z
		 M162,54.3c0-0.1-0.1-0.1-0.1-0.2c0.1,0,0.1-0.1,0.2-0.1C162.1,54.1,162,54.2,162,54.3z M162.1,53.9c-0.1,0-0.1,0.1-0.2,0.1
		c0-0.1-0.1-0.3-0.1-0.4C161.9,53.7,162.1,53.8,162.1,53.9C162.1,53.9,162.1,53.9,162.1,53.9z M162.2,53.8c-0.2-0.1-0.3-0.1-0.4-0.2
		c-0.1-0.3-0.1-0.5-0.1-0.7c0.2-0.1,0.5-0.2,0.8-0.2C162.4,53.1,162.3,53.4,162.2,53.8z M165.1,52.2c0-0.1,0-0.3,0-0.4
		c0.1,0.2,0.3,0.5,0.4,0.8C165.4,52.5,165.3,52.4,165.1,52.2z M171,55.3c0-0.3-0.1-0.7-0.2-1.1c0.1,0.1,0.3,0.2,0.4,0.4
		C171.1,54.7,171,55,171,55.3z M172,53.9C172.1,53.9,172.1,53.9,172,53.9c0,0,0,0.1,0,0.1C172.1,53.9,172.1,53.9,172,53.9z
		 M173.1,54.7c-0.2-0.3-0.4-0.6-0.6-0.9c0,0,0,0.2,0.1,0.5c-0.1-0.1-0.2-0.1-0.2-0.1c0.1,0,0.1,0.1,0.2,0.1c0,0.3,0,0.7,0,1.1
		c-0.1-0.5-0.2-1-0.3-1.5c0.2-0.1,0.4-0.1,0.8-0.1c0,0,0.1,0,0.1,0c0,0.3,0,0.6,0.1,1C173.1,54.7,173.1,54.7,173.1,54.7z
		 M173.3,53.5c0,0.1,0,0.2,0.1,0.3c0,0,0,0,0,0C173.4,53.7,173.3,53.6,173.3,53.5z M173.4,53.8C173.4,53.8,173.4,53.8,173.4,53.8
		c0.1,0.2,0.1,0.3,0.1,0.5C173.4,54.2,173.4,54,173.4,53.8z M176.6,54.1c-0.1-0.4-0.1-0.6-0.1-0.6c0-0.1,0-0.1,0-0.2
		c0,0.1,0,0.1,0.1,0.2C176.6,53.5,176.6,53.8,176.6,54.1z M178.3,55.2c0-0.2,0.1-0.4,0.1-0.6c0,0.1,0,0.1,0,0.2
		C178.4,55,178.3,55.1,178.3,55.2z M178.5,54.5c0-0.1,0-0.2,0-0.2c0.1-0.2,0.2-0.4,0.3-0.4c0,0,0.1,0.1,0.1,0.1
		C178.7,54.1,178.6,54.3,178.5,54.5z M183.4,55.9C183.3,55.9,183.3,55.9,183.4,55.9c0-0.1,0-0.1,0-0.1
		C183.4,55.8,183.4,55.9,183.4,55.9z M184.2,56.5c0,0,0-0.1-0.1-0.1c-0.1-0.4-0.1-0.8-0.1-1.1c0,0,0,0,0.1,0c0,0.2,0.1,0.4,0.1,0.6
		C184.2,56,184.2,56.3,184.2,56.5z M185.7,56.7c-0.2-0.2-0.3-0.4-0.4-0.7c0.1-0.2,0.4-0.4,0.6-0.5C185.7,56,185.7,56.3,185.7,56.7z
		 M187.9,54.8c0-0.4,0-0.7,0-1c0,0.4,0.1,0.7,0.1,1.1C188,54.9,187.9,54.9,187.9,54.8z M188.3,55.3c0,0,0-0.1,0-0.1
		c0.2,0.3,0.3,0.6,0.5,0.9C188.6,55.8,188.4,55.5,188.3,55.3z M189.2,56.8c0-0.1-0.1-0.2-0.1-0.2c0,0,0.1,0.1,0.1,0.1
		C189.2,56.7,189.2,56.8,189.2,56.8z M189.9,55.9c-0.2-1.1-0.2-1.9-0.2-2.5C190.2,54.3,190.2,55.1,189.9,55.9z M192.3,56.1
		c0.1,0.1,0.1,0.1,0.2,0.2c-0.1,0-0.1,0-0.1,0.1C192.3,56.3,192.3,56.2,192.3,56.1z M192.4,57.3c0-0.1,0-0.3,0-0.4c0,0,0,0,0.1,0
		c0,0.1,0,0.2,0,0.3C192.4,57.2,192.4,57.3,192.4,57.3z M192.5,56.5c0,0.2-0.1,0.3-0.1,0.5c0,0,0,0-0.1,0c0-0.1,0-0.3,0-0.4
		C192.4,56.5,192.4,56.4,192.5,56.5C192.5,56.4,192.5,56.4,192.5,56.5C192.5,56.4,192.5,56.4,192.5,56.5z M192.6,56.2
		c-0.1-0.3-0.1-0.6-0.2-0.8C192.5,55.6,192.6,55.8,192.6,56.2C192.6,56.1,192.6,56.1,192.6,56.2z M194.6,57.1
		c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0.1,0.2,0.1,0.2,0.2C194.7,57,194.6,57,194.6,57.1z M194.7,56.9c-0.1-0.1-0.2-0.1-0.2-0.1
		c0,0,0-0.1,0-0.1c0.1-0.1,0.2-0.1,0.4-0.1C194.7,56.6,194.7,56.8,194.7,56.9z M196.2,56c0-0.1,0-0.2,0-0.3c0.1,0.2,0.1,0.3,0.2,0.5
		C196.3,56.2,196.2,56.1,196.2,56z M197.3,57.6c0,0,0-0.1,0-0.1C197.3,57.5,197.3,57.5,197.3,57.6C197.3,57.6,197.3,57.6,197.3,57.6
		z M197.3,57.5C197.3,57.5,197.3,57.5,197.3,57.5c-0.1-0.2-0.2-0.4-0.3-0.5c0-0.1,0-0.1,0-0.2c0.1,0,0.3,0.1,0.4,0.2
		C197.4,57.2,197.4,57.3,197.3,57.5z M197,56.8c0-0.4,0-0.9,0-1.2c0.4,0.4,0.5,0.8,0.4,1.4C197.2,56.8,197.1,56.8,197,56.8z
		 M200.7,54.6c0.1,0.2,0.2,0.4,0.3,0.6c-0.1,0.3-0.2,0.5-0.3,0.8C200.6,55.5,200.6,55,200.7,54.6z M200.9,56.8
		c-0.1-0.2-0.1-0.4-0.2-0.6c0.1-0.5,0.2-0.9,0.3-1.1c0.1,0.3,0.2,0.6,0.3,0.8C201.1,56.3,201,56.6,200.9,56.8z M201.4,56.7
		c0.1,0.3,0.1,0.6,0.1,0.9C201.5,57.3,201.4,57,201.4,56.7z M202.6,58.9C202.6,58.9,202.6,58.9,202.6,58.9c-0.1-0.2-0.1-0.3-0.1-0.5
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.4,0.1-0.7,0.2-1C202.5,58,202.5,58.5,202.6,58.9z M202.8,56.9c-0.2,0.2-0.4,0.5-0.4,0.8
		c0-0.2,0-0.3,0-0.4c0.1,0,0.1-0.1,0.2-0.1c0,0-0.1,0-0.2,0.1c0-0.3,0-0.4,0-0.4c-0.3,0.2-0.4,0.5-0.6,0.7c0,0-0.1,0-0.1,0.1
		c-0.1-0.5-0.2-0.9-0.3-1.3c0.1-0.7,0.5-1.3,0.7-1.6c0.4,0.6,0.6,1.2,0.8,1.9C202.9,56.7,202.9,56.8,202.8,56.9z M204,58.8
		c-0.1-0.2-0.2-0.3-0.3-0.5c0-0.1,0-0.2,0-0.3c0.1-0.2,0.3-0.3,0.5-0.4C204.1,58,204,58.4,204,58.8z M205.4,56.7c0-0.1,0-0.2,0-0.3
		C205.4,56.5,205.4,56.6,205.4,56.7C205.4,56.7,205.4,56.7,205.4,56.7z M205.5,56.5c0-0.1-0.1-0.3-0.1-0.4c0,0,0,0,0-0.1
		c0.1-0.3,0.3-0.6,0.4-0.8c0.1,0.1,0.2,0.2,0.3,0.4C205.8,56,205.6,56.3,205.5,56.5z M206.2,57c0-0.4,0-0.7,0-1
		c0,0.1,0.1,0.2,0.1,0.2C206.3,56.5,206.3,56.7,206.2,57C206.2,57,206.2,57,206.2,57z M206.4,57.2c0-0.3,0-0.6,0-0.8
		c0.2,0.5,0.3,1,0.4,1.4C206.6,57.5,206.5,57.3,206.4,57.2z M210.7,59.6c0-0.1,0-0.3,0-0.4c0,0,0,0,0.1,0c0,0.1,0,0.2,0,0.3
		C210.7,59.5,210.7,59.6,210.7,59.6z M210.7,59.2C210.7,59.2,210.7,59.2,210.7,59.2c-0.1-0.6-0.2-1.2-0.3-1.6c0-0.1,0-0.2,0-0.3
		c0.3,0.3,0.4,0.7,0.4,1.1C210.8,58.7,210.7,59,210.7,59.2z M211.1,58c-0.3-0.4-0.5-0.6-0.6-0.8c0-0.3,0.1-0.6,0.2-0.8
		C210.8,56.7,211,57.3,211.1,58C211.2,58,211.1,58,211.1,58z M212.7,59C212.7,59,212.7,59,212.7,59c0.1,0,0.1,0.1,0.1,0.1
		c0,0,0,0,0,0C212.7,59.1,212.7,59,212.7,59z M212.8,59.1C212.8,59.1,212.8,59.1,212.8,59.1C212.8,59.1,212.8,59.1,212.8,59.1
		C212.8,59.1,212.8,59.1,212.8,59.1z M213,58.7c-0.1-0.5-0.1-0.9,0-1.2c0,0,0,0.1,0,0.1C213,57.8,213,58.2,213,58.7z M214.5,60
		c0-0.1,0-0.2-0.1-0.3c0,0,0.1,0.1,0.1,0.1C214.5,59.9,214.5,60,214.5,60z M214.5,59.7C214.5,59.7,214.5,59.7,214.5,59.7
		C214.5,59.7,214.5,59.7,214.5,59.7c0,0,0,0.1,0,0.1c0,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.3-0.1-0.4c0.1,0,0.2-0.1,0.3-0.1
		C214.6,59.4,214.6,59.6,214.5,59.7z M219.3,60.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1-0.2-0.1-0.3-0.2-0.5c0.1,0.1,0.3,0.1,0.4,0.2
		C219.3,59.9,219.3,60,219.3,60.1C219.3,60,219.3,60,219.3,60.1z M220.1,61.4c-0.1-0.2-0.1-0.3-0.1-0.5c0,0,0,0,0,0c0,0,0,0,0,0
		c0-0.2-0.1-0.4-0.1-0.5c0,0,0.1,0.1,0.1,0.2C220,60.8,220,61.1,220.1,61.4C220.1,61.4,220.1,61.4,220.1,61.4z M220,60.3
		c0,0-0.1-0.1-0.2-0.1c0-0.1,0-0.3,0-0.4c0.1,0,0.1-0.1,0.2-0.1c0,0-0.1,0-0.2,0.1c0-0.1,0-0.2,0-0.2c0.1-0.1,0.3-0.2,0.5-0.3
		C220.2,59.6,220,60,220,60.3z M221.5,61.3c-0.2-0.2-0.3-0.5-0.5-0.7c0.1-0.2,0.4-0.4,0.5-0.5C221.5,60.6,221.5,61,221.5,61.3z
		 M223.6,59.2c0-0.4,0-0.7,0.1-0.9C223.6,58.5,223.6,58.8,223.6,59.2z M228.1,62.3c0-0.1,0-0.2,0-0.4c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0.1,0,0.2,0,0.3C228.1,62.2,228.1,62.3,228.1,62.3z M228.1,61.9C228.1,61.9,228.1,61.9,228.1,61.9
		C228.1,61.9,228.1,61.9,228.1,61.9C228.1,61.9,228.1,61.9,228.1,61.9z M228.2,61.7C228.1,61.8,228.1,61.8,228.2,61.7
		c-0.1-0.2-0.2-0.5-0.2-0.8c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0.1C228.2,61.3,228.2,61.5,228.2,61.7z M229.8,60.9
		c0-0.1,0-0.2-0.1-0.2c0,0,0,0,0.1,0C229.8,60.8,229.8,60.8,229.8,60.9z M230.5,61.1c-0.1-0.2-0.2-0.4-0.3-0.5c0.1,0,0.2,0,0.3,0
		C230.5,60.8,230.5,60.9,230.5,61.1C230.5,61.1,230.5,61.1,230.5,61.1z M231.4,61.7c-0.1-0.2-0.2-0.4-0.3-0.6c0.1,0,0.2,0,0.3,0.1
		C231.5,61.3,231.5,61.5,231.4,61.7z M231.9,61.2C231.9,61.2,231.9,61.2,231.9,61.2C231.9,61.2,231.9,61.2,231.9,61.2
		C231.9,61.2,231.9,61.2,231.9,61.2z M231.9,61.5c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0C231.9,61.4,232,61.4,231.9,61.5
		C232,61.5,231.9,61.5,231.9,61.5z M235.1,62c-0.2-0.6-0.3-1.1-0.3-1.6c0.1,0.3,0.2,0.5,0.3,0.8C235.1,61.4,235.1,61.6,235.1,62z
		 M235.1,61.2C235.1,61.2,235.1,61.3,235.1,61.2c0,0.1,0,0.2,0,0.5C235.1,61.5,235.1,61.4,235.1,61.2z M235.9,63.2
		C235.9,63.2,235.9,63.2,235.9,63.2c0-0.1,0-0.1,0-0.1C236,63.1,236,63.2,235.9,63.2z M240.5,62.7c0.1,0,0.3,0,0.5,0.1
		c0,0,0.1,0,0.1,0c0,0.3,0.1,0.7,0.1,1C241,63.4,240.7,63,240.5,62.7z M241.7,64.8c0-0.1-0.1-0.3-0.1-0.5c0.1,0,0.1,0.1,0.2,0.1
		C241.8,64.6,241.7,64.7,241.7,64.8z M244.9,65.4c0-0.1,0-0.3,0-0.4c0,0,0,0,0.1,0c0,0.1,0,0.2,0,0.3
		C244.9,65.3,244.9,65.4,244.9,65.4z M244.9,65C244.9,65,244.9,65,244.9,65C244.9,65,244.9,65,244.9,65c0-0.6,0-1.1-0.1-1.6
		c0.1,0.3,0.2,0.5,0.2,0.8C245,64.5,244.9,64.7,244.9,65z M247.1,65.4c-0.1-0.1-0.1-0.2-0.2-0.4c0.1,0.1,0.2,0.1,0.2,0.2
		C247.1,65.3,247.1,65.3,247.1,65.4z M247.1,65.2c-0.1-0.1-0.2-0.1-0.2-0.2c0,0,0-0.1-0.1-0.1c0.1,0,0.2-0.1,0.4-0.1
		C247.2,64.9,247.2,65.1,247.1,65.2z M248.6,66c0-0.1,0-0.2-0.1-0.3c0,0,0.1,0.1,0.1,0.1C248.6,65.9,248.6,66,248.6,66z M231.3,60.7
		C231.3,60.7,231.3,60.6,231.3,60.7C231.3,60.6,231.3,60.7,231.3,60.7z M39.4,57.2c0,0,0.1,0.1,0.1,0.1
		C39.4,57.3,39.4,57.2,39.4,57.2z M59.2,54.8c0,0,0.1,0.1,0.1,0.1C59.2,54.8,59.2,54.8,59.2,54.8z M48.9,56c0.1,0,0.1,0.1,0.1,0.1
		C48.9,56,48.9,56,48.9,56z M7.4,61.2c0.1-0.1,0.1-0.2,0.2-0.3C7.6,60.9,7.5,61,7.4,61.2z M75.1,53.4c-0.1-0.1-0.2-0.2-0.2-0.2
		C75,53.3,75.1,53.3,75.1,53.4z M67.7,53.9c0.1,0,0.1,0.1,0.1,0.1C67.8,53.9,67.7,53.9,67.7,53.9z M23.2,58.3
		c0.1-0.1,0.1-0.2,0.2-0.3C23.5,58,23.4,58.1,23.2,58.3z M15.6,59.7c0.1-0.1,0.1-0.2,0.2-0.3C15.8,59.4,15.7,59.5,15.6,59.7z
		 M9.3,60.8c0.1-0.1,0.1-0.2,0.2-0.3C9.5,60.5,9.4,60.7,9.3,60.8z M34.6,56.5c0.1-0.1,0.1-0.2,0.2-0.3
		C34.8,56.2,34.7,56.3,34.6,56.5z M15,59.8c0.1-0.1,0.1-0.2,0.2-0.3C15.2,59.5,15.1,59.6,15,59.8z M134.2,51.5
		c-0.1-0.5-0.2-0.7-0.2-0.7C134.1,51,134.1,51.2,134.2,51.5z M165.2,51.3c0.1-0.7,0.2-1.1,0.2-1.1C165.3,50.5,165.2,50.9,165.2,51.3
		z M95.5,50.5c-0.1-0.2-0.2-0.3-0.2-0.3C95.4,50.4,95.4,50.5,95.5,50.5z M172.1,52.7c0-1.1,0.1-2,0.1-2C172,51.3,172,52,172.1,52.7z
		 M172.4,51.7c-0.1-0.2-0.2-0.3-0.2-0.3C172.3,51.5,172.3,51.6,172.4,51.7z M164.7,51.1c-0.1-0.2-0.2-0.3-0.2-0.3
		C164.6,50.9,164.6,51,164.7,51.1z M173.2,50.9c0.1-0.4,0.1-0.6,0.1-0.6C173.3,50.4,173.2,50.6,173.2,50.9z M155.5,48.9
		c-0.3-0.7-0.5-1-0.5-1C155.2,48.2,155.3,48.6,155.5,48.9z M155.7,50.6c0.1-0.1,0.1-0.2,0.2-0.3C155.9,50.3,155.8,50.4,155.7,50.6z
		 M160.8,52.4c0-0.3,0.1-0.5,0.2-0.7C161,51.7,160.9,52,160.8,52.4z M204.1,54.9c0.1-0.1,0.1-0.2,0.2-0.2
		C204.3,54.7,204.2,54.8,204.1,54.9z M219.6,57.2c0.1-0.1,0.1-0.2,0.2-0.2C219.8,56.9,219.7,57,219.6,57.2z M221.5,57.5
		c0.1-0.1,0.1-0.2,0.2-0.2C221.7,57.2,221.6,57.3,221.5,57.5z M209.9,55.7c0.1-0.1,0.1-0.2,0.2-0.2C210.1,55.5,210,55.6,209.9,55.7z
		 M227.2,58.4c0.1-0.1,0.1-0.1,0.2-0.2C227.4,58.2,227.3,58.3,227.2,58.4z M149.8,50.3c0.1-0.1,0.1-0.2,0.2-0.3
		C150,50.1,150,50.2,149.8,50.3z M185.8,52.8c0.1-0.1,0.1-0.2,0.2-0.2C186,52.6,185.9,52.7,185.8,52.8z M183.9,52.7
		c0.1-0.1,0.1-0.2,0.2-0.2C184.1,52.4,184,52.5,183.9,52.7z M191.6,53.5c0.1-0.1,0.1-0.2,0.2-0.2C191.8,53.2,191.7,53.3,191.6,53.5z
		 M170.5,51.5c-0.1-0.2-0.2-0.3-0.2-0.3C170.4,51.3,170.4,51.4,170.5,51.5z M109.4,50c0.1-0.1,0.1-0.2,0.2-0.3
		C109.6,49.8,109.5,49.9,109.4,50z M111.3,50c0.1-0.1,0.1-0.2,0.2-0.3C111.5,49.7,111.4,49.8,111.3,50z M107,50.3
		c0.1-0.7,0.3-1.2,0.3-1.2C107.1,49.4,107,49.8,107,50.3z M113.9,51c0.1-1.1,0.1-2,0.1-2C113.9,49.6,113.8,50.3,113.9,51z
		 M97.4,48.8c-0.2-0.7-0.5-1-0.5-1C97.1,48.1,97.3,48.5,97.4,48.8z M102.5,51.8c0-0.3,0.1-0.5,0.2-0.7
		C102.7,51.1,102.6,51.4,102.5,51.8z M97.4,50.5c-0.1-0.2-0.2-0.3-0.2-0.3C97.3,50.3,97.3,50.4,97.4,50.5z M115.1,49.1
		c0.1-0.4,0.1-0.7,0.1-0.7C115.2,48.6,115.1,48.8,115.1,49.1z M139.3,48.3c0.1-0.3,0.3-0.7,0.5-1C139.8,47.4,139.6,47.6,139.3,48.3z
		 M129.7,50c-0.1-0.4-0.1-0.8-0.3-1.2C129.5,48.9,129.6,49.3,129.7,50z M134.9,49.9c-0.1-0.2-0.2-0.3-0.2-0.3
		C134.8,49.7,134.8,49.8,134.9,49.9z M133,49.9c-0.1-0.2-0.2-0.3-0.2-0.3C132.9,49.7,132.9,49.8,133,49.9z M147.9,50.3
		c0.1-0.1,0.1-0.2,0.2-0.3C148.1,50,148,50.1,147.9,50.3z M117.1,49.9c0.1-0.1,0.1-0.2,0.2-0.3C117.3,49.6,117.3,49.7,117.1,49.9z
		 M121.6,49c0-0.3-0.1-0.5-0.1-0.7C121.5,48.4,121.5,48.6,121.6,49z M127.2,49.8c-0.1-0.2-0.2-0.3-0.2-0.3
		C127.1,49.7,127.1,49.7,127.2,49.8z M122.8,50.9c0-0.7,0-1.4-0.1-2C122.7,48.9,122.8,49.8,122.8,50.9z"
          />
        </svg>

        <div className="absolute ml-[38%]  w-full h-full flex top-14 text-[6.5vw] text-white text-center font-black">
          <h2 className="glow">REGISTRATION</h2>
          {/* <PascendiGregis className=" absolute w-full h-full fill-current logo-shadow logo-size right-0" /> */}
        </div>
      </section>

      <section className="bg-[#03264f] text-base py-10 -translate-y-1 px-10 text-blue-200">
        <div className="w-10/12 flex flex-col lg:flex-row mx-auto justify-center items-center">
          <div className=" w-full flex flex-col py-5">
            <h1 className="text-center z-20 font-black w-full lg:text-left  text-[calc(2.4rem+2vw)] leading-tight text-[#6b30ff]">
              CODING BOOTCAMP
            </h1>
            <p className="text-white text-sm md:text-lg lg:text-left text-center max-w-[600px] mx-auto lg:mx-0">
              LEARN TO CODE FROM SCRATCH WITH A REAL-LIFE PROJECT BY A FORMER
              PROFESSOR IN A CATHOLIC UNIVERSITY IN POLAND
            </p>
          </div>
          <div className="w-full ">
            <StaticImage
              alt="Coding Bootcamp"
              aria="Coding Bootcamp Image"
              src={"../images/coding-flyer.png"}
              loading="eager"
              className="w-full object-fit "
            />
          </div>
        </div>
        <div className="w-11/12 lg:w-1/2 mx-auto py-20">
          <h2 className="text-3xl font-black text-[#9267ff]">PROJECT </h2>
          <p className="text-lg max-w-[600px] leading-loose mx-auto">
            Software Engineering is a fast growing industry. Here we have a
            fantastic and unique opportunity to get your hands on basic
            programming. Students will have the opportunity to build, design,
            and code a remote control car on a project-base learning experience
            that goes from the very basics of programming to a more advance
            level of debugging and calibration using Python programming
            language. Python is widely recognized as the GO-TO framework for
            Scientific Data Analysis, automation, 3d printers, etc....
          </p>
        </div>
        <div className="w-11/12 lg:w-1/2 mx-auto py-20">
          <h2 className="text-3xl font-black text-[#9267ff]">REQUIREMENTS </h2>
          <p className="text-lg max-w-[600px]  mx-auto">
            Knowledge of<i className="text-red-400">*</i> :
            <ul className="list-disc pl-8 leading-loose">
              <li>Math (Multiplication, Division, etc...)</li>
              <li>Graphing (X and Y Axis)</li>
              <li>Calculating Percentages</li>
            </ul>
            <span className="text-base leading-tight text-red-400">
              *If students are too young to understand the basic requirements,
              parents may join them. Laptop or Tablet with WiFi access
            </span>
          </p>
        </div>
        <div className="w-11/12 lg:w-1/2 mx-auto py-20">
          <h2 className="text-3xl font-black text-[#9267ff]">REQUIREMENTS </h2>
          <p className="text-lg max-w-[600px]  mx-auto">
            <b>Cost:</b>
            <br />
            $50 (for all 3 classes)
            <br />
            <br />
            <b>Dates:</b> <br />
            Saturday, January 14th
            <br />
            Saturday, January 21st
            <br />
            Saturday, January 28th
            <br />
            <br />
            <b>Time:</b>
            <br />
            12PM - 4PM
            <br />
            <br />
            <b>For questions:</b>
            <br />
            E-Mail: pascendigregis@gmail.com <br />
            <br />
            Limited Space Available, first come first serve.
          </p>
        </div>
        <a
          href="https://buy.stripe.com/bIY9Ej72k9wZ4mc6ou"
          className="btn max-w-max py-3 px-7 text-4xl mx-auto mb-20"
        >
          REGISTER
        </a>
      </section>
    </Layout>
  )
}

export default CodingCamp
